import {
  SliderInterface,
  SliderItemInterface,
} from '~/components/organisms/slider/Slider';
import { CztImage } from '../atoms/image';
import { CarouselInterface } from '~/components/organisms/carousel/Carousel';
import { CarouselItemInterface } from '~/components/molecules/carouselItem/CarouselItem';
import {
  ContentBlockInterface,
  isPositionEnum,
  Position,
} from '~/components/organisms/contentBlock/ContentBlock';
import {
  GridItem,
  GridListInterface,
} from '~/components/organisms/gridList/GridList';
import { GalleryInterface } from '~/components/organisms/gallery/Gallery';
import {
  CarouselWidget,
  CarouselWidgetItem,
  CertifiedSubjectsWidget,
  EventtiaEventPage,
  EventtiaEventsWidget,
  GalleryWidget,
  GalleryWidgetItem,
  GridWidget,
  GridWidgetItem,
  HtmlContentWidget,
  IframeWidget,
  NewsletterFormWidget,
  PanoramicPhotoWidget,
  SafeTravelsWidget,
  SliderWidget,
  SliderWidgetItem,
  TabbedContentWidget,
  TabbedContentWidgetItem,
  VenueFinderWidget,
} from '~/app/core/apiClient/api';
import { ButtonInterface } from '~/components/atoms/button/Button';
import { VideoInterface } from '~/components/atoms/video/Video';
import { format, parse, timezoneFormat } from '~/utils/date-fns';
import toBoolean from '../toBoolean';
import { TabItemInterface } from '~/components/molecules/tabs/Tabs';
import { TabsWidgetInterface } from '~/components/templates/common/TabsWidget';
import { CztWidgets } from '~/utils/views/widgets';
import { defaultEventTimeFormat, RFC3339Format } from '~/utils/dateTime';
import { isAspectRatio, ThemeRatios } from '~/utils/theme';
import { IframeBlockInterface } from '~/components/organisms/iframeBlock/IframeBlock';
import { GalleryItemInterface } from '~/components/molecules/galleryItem/GalleryItem';
import {
  EventtiaListInterface,
  EventtiaListItem,
} from '~/components/organisms/eventtiaList/EventtiaList';

import eventtiaFallback from '~/assets/images/eventtia-default-theme.png';
import * as urlTools from 'url';
import { ImageInterface } from '~/components/atoms/image/Image';

import WidgetStyleEnum = GridWidget.WidgetStyleEnum;
import ThemeStyleEnum = HtmlContentWidget.ThemeStyleEnum;
import { SafeTravelsListInterface } from '~/components/templates/safeTravels/SafeTravelsList';
import { PanoramaInterface } from '~/components/organisms/panorama/Panorama';
import { NewsletterFormInterface } from '~/components/templates/common/NewsletterForm';
import { VenueFinderInterface } from '~/components/templates/common/VenueFinder';
import { CertifiedSubjectsListInterface } from '~/components/templates/certifiedSubjects/CertifiedSubjectsList';
import apiUrl from '~/app/core/apiClient/apiUrl';

export function createGridList(widget: GridWidget): GridListInterface {
  const items =
    widget.items && widget.items.length > 0
      ? widget.items.map((item: GridWidgetItem) => createGridItem(item))
      : [];

  let title = widget.title || '';
  if (widget.hideTitle) {
    title = '';
  }

  // Check if ratio-string from api exists in enum
  let imageRatio: ThemeRatios | undefined;
  if (
    widget.itemImageAspectRatio &&
    isAspectRatio(widget.itemImageAspectRatio)
  ) {
    imageRatio = (ThemeRatios as any)[widget.itemImageAspectRatio];
  }

  return {
    className: CztWidgets.GRID,
    guid: widget.nodeGuid || 'unknown',
    title,
    items,
    imageAspectRatio: imageRatio,
    image: widget.backgroundImage
      ? createImage(
          widget.backgroundImage,
          widget.title
            ? widget.title
            : widget.documentName
            ? widget.documentName
            : ''
        )
      : undefined,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
    maxItems: widget.maxItems ? widget.maxItems : 9,
    nextPageCount: widget.itemsCountNextPage,
    pageSize: widget.pageSize,
    type: widget.widgetStyle || WidgetStyleEnum.Classic,
  };
}

export function createGridItem(widget: GridWidgetItem): GridItem {
  let date;
  let endDate;

  if (widget.date) {
    const parsedDate = format(parse(widget.date), RFC3339Format);
    if (parsedDate) {
      date = parsedDate;
    }
  }

  if (widget.dateTo) {
    const parsedEndDate = format(parse(widget.dateTo), RFC3339Format);
    if (parsedEndDate) {
      endDate = parsedEndDate;
    }
  }

  return {
    date,
    endDate,
    description: widget.shortDesc || '',
    image: widget.image
      ? createImage(
          widget.image,
          widget.title
            ? widget.title
            : widget.documentName
            ? widget.documentName
            : ''
        )
      : createImage('', ''),
    imageFilter: widget.imageFilterType,
    link: widget.url || '',
    linkText: widget.urlText || '',
    perex: widget.perex || undefined,
    title: widget.title || '',
  };
}

export function createCarousel(widget: CarouselWidget): CarouselInterface {
  const items =
    widget.items && widget.items.length > 0
      ? widget.items.map((item: CarouselWidgetItem) => createCarouselItem(item))
      : [];

  let title = widget.title || '';
  if (widget.hideTitle) {
    title = '';
  }

  return {
    className: CztWidgets.CAROUSEL,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
    title,
    items,
  };
}

export function createSlider(widget: SliderWidget): SliderInterface {
  const superTitle =
    !widget.hideTitle && widget.title ? widget.title : undefined;
  const slides = widget.items
    ? widget.items.map((slide: SliderWidgetItem) =>
        createSlide(slide, superTitle)
      )
    : [];
  let sliderRatio: ThemeRatios | undefined;
  if (widget.aspectRatio && isAspectRatio(widget.aspectRatio)) {
    sliderRatio = (ThemeRatios as any)[widget.aspectRatio];
  }
  return {
    className: CztWidgets.SLIDER,
    items: slides,
    delay: widget.sliderDelaySec || 0,
    contentWidth: widget.useContentWidth || false,
    ratio: sliderRatio,
  };
}

export function createCarouselItem(
  data: CarouselWidgetItem
): CarouselItemInterface {
  // We need to validate and sanitize the date, because Swagger gen returns 'Date' type, but
  // the value is actually a string. So we first parse the date, check if it is valid
  // and then format it back to a default date time format
  let date: Date | string | undefined = data.date
    ? parse(data.date)
    : undefined;
  if (date) {
    date = isNaN(date.getTime()) ? undefined : format(date, RFC3339Format);
  }
  let endDate: Date | string | undefined = data.dateTo
    ? parse(data.dateTo)
    : undefined;
  if (endDate) {
    endDate = isNaN(endDate.getTime())
      ? undefined
      : format(endDate, RFC3339Format);
  }

  return {
    image: data.image
      ? createImage(
          data.image,
          data.title ? data.title : data.documentName ? data.documentName : ''
        )
      : createImage('', ''),
    imageFilter: data.imageFilterType,
    title: data.title ? data.title : '',
    url: data.url || '#',
    date,
    endDate,
    location: data.region ? data.region : undefined,
  };
}

export function createSlide(
  data: SliderWidgetItem,
  superTitle?: string
): SliderItemInterface {
  return {
    backgroundImage: data.image
      ? createImage(
          data.image,
          data.title ? data.title : data.documentName ? data.documentName : ''
        )
      : createImage('', ''),
    content: data.description ? data.description : '',
    hideTitle: data.hideTitle,
    imageFilter: data.imageFilterType,
    largeHeadline: false,
    title: data.title ? data.title : '',
    button: createButton(data),
    showVideoControls: data.showVideoControls,
    sideImage: data.topLayerImage
      ? createImage(
          data.topLayerImage,
          data.title ? data.title : data.documentName ? data.documentName : ''
        )
      : undefined,
    sideContent: data.sideContent || undefined,
    superTitle,
    video: data.video ? createVideo(data.video) : undefined,
  };
}

export function createIframeBlock(data: IframeWidget): IframeBlockInterface {
  return {
    className: CztWidgets.IFRAME,
    isBottomSpacingCollapsed: data.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: data.isTopSpacingCollapsed || false,
    title: !data.hideTitle ? data.title || '' : '',
    url: data.url || '',
    height: data.height || undefined,
  };
}

export function createImage(src: string, alt: string): CztImage {
  const height = getUrlParameterByName('height', src);
  const width = getUrlParameterByName('width', src);
  return {
    src,
    alt,
    height: height && !isNaN(Number(height)) ? Number(height) : undefined,
    width: width && !isNaN(Number(width)) ? Number(width) : undefined,
    sizes: getUrlParameterByName('sizes', src) || undefined,
    srcset: getUrlParameterByName('srcset', src) || undefined,
    title: getUrlParameterByName('title', src) || alt,
  };
}

export function createButton(data: SliderWidgetItem): ButtonInterface {
  return {
    url: data.url || '',
    label: data.urlText || '',
  };
}

export function createVideo(src: string): VideoInterface | undefined {
  const videoId = getVideoId(src) || '';
  const autoPlay = toBoolean(getUrlParameterByName('autoplay', src));
  const disableKeyboard = toBoolean(getUrlParameterByName('disablekb', src));
  const loop = toBoolean(getUrlParameterByName('loop', src));
  const modestBranding = toBoolean(
    getUrlParameterByName('modestbranding', src)
  );
  const mute = toBoolean(getUrlParameterByName('mute', src));

  return {
    videoId,
    src,
    autoPlay: autoPlay === undefined ? true : autoPlay,
    disableKeyboard: disableKeyboard === undefined ? true : disableKeyboard,
    fullScreenButton:
      toBoolean(getUrlParameterByName('fullscreen', src)) || false,
    loop: loop === undefined ? true : loop,
    modestBranding: modestBranding === undefined ? true : modestBranding,
    showControls: toBoolean(getUrlParameterByName('controls', src)) || false,
    mute: mute === undefined ? true : mute,
  };
}

export function createGallery(widget: GalleryWidget): GalleryInterface {
  const items = widget.items
    ? widget.items.map((galleryItem: GalleryWidgetItem) => {
        return createGalleryItem(galleryItem);
      })
    : [];
  return {
    className: CztWidgets.GALLERY,
    items,
    type: widget.widgetStyle || GalleryWidget.WidgetStyleEnum.Slider,
  };
}

export function createGalleryItem(
  data: GalleryWidgetItem
): GalleryItemInterface {
  return {
    image: data.image
      ? createImage(
          data.image,
          data.title ? data.title : data.documentName ? data.documentName : ''
        )
      : createImage('', ''),
    imageFilter: data.imageFilterType,
    title: data.title || '',
    subtitle: '',
  };
}

export function createContentBlock(
  widget: HtmlContentWidget
): ContentBlockInterface {
  let title = `${widget.title}`;

  if (widget.hideTitle) {
    title = '';
  }

  return {
    className: CztWidgets.HTML,
    title,
    image: widget.image
      ? createImage(
          widget.image,
          widget.title
            ? widget.title
            : widget.documentName
            ? widget.documentName
            : ''
        )
      : undefined,
    content: `${widget.content}`,
    caption: widget.imageCaption,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
    position: isPositionEnum(widget.imagePosition)
      ? widget.imagePosition
      : Position.LEFT,
    themeStyle: widget.themeStyle || ThemeStyleEnum.None,
  };
}

export function createTabs(widget: TabbedContentWidget): TabsWidgetInterface {
  const tabs =
    widget.items?.map((item: TabbedContentWidgetItem) => {
      return createTabItem(item);
    }) || [];
  return {
    className: CztWidgets.TABS,
    items: tabs,
    image: widget.image
      ? createImage(widget.image, widget.title || '')
      : undefined,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
  };
}

export function createTabItem(data: TabbedContentWidgetItem): TabItemInterface {
  return {
    content: data.content || '',
    label: data.title || '',
    link: data.url || undefined,
    buttonText: data.urlText || undefined,
    sideContent: data.sideContent,
  };
}

export function createEventtiaListWidget(
  widget: EventtiaEventsWidget
): EventtiaListInterface {
  const items =
    widget.items && widget.items.length > 0
      ? widget.items.map((item) =>
          createEventtiaListItemFromEventtiaEventPage(item)
        )
      : [];

  let title = widget.title || '';
  if (widget.hideTitle) {
    title = '';
  }

  return {
    className: CztWidgets.EVENTTIA_EVENTS,
    guid: widget.nodeGuid || 'unknown',
    title,
    items,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
    maxItems: widget.maxItems ? widget.maxItems : 9,
    nextPageCount: widget.itemsCountNextPage,
    pageSize: widget.pageSize,
  };
}

export function createEventtiaListItemFromEventtiaEventPage(
  data: EventtiaEventPage
): EventtiaListItem {
  {
    let coords;
    if (data && data.latitude && data.longitude) {
      coords = {
        lat: data.latitude,
        lng: data.longitude,
      };
    }
    const dates = [];
    if (data.localStartDateTimeOffset) {
      dates.push(
        `${timezoneFormat(
          data.localStartDateTimeOffset,
          defaultEventTimeFormat,
          data.isVirtual ? data.virtualTimezone : data.timezone
        )} GMT +${
          data.isLocalStartDateTimeDST
            ? data.isVirtual
              ? data.virtualTimezoneOffsetDST
              : data.timezoneOffsetDST
            : data.isVirtual
            ? data.virtualTimezoneOffset
            : data.timezoneOffset
        }`
      );
    }
    if (data.localEndDateTimeOffset) {
      dates.push(
        `${timezoneFormat(
          data.localEndDateTimeOffset,
          defaultEventTimeFormat,
          data.isVirtual ? data.virtualTimezone : data.timezone
        )} GMT +${
          data.isLocalEndDateTimeDST
            ? data.isVirtual
              ? data.virtualTimezoneOffsetDST
              : data.timezoneOffsetDST
            : data.isVirtual
            ? data.virtualTimezoneOffset
            : data.timezoneOffset
        }`
      );
    }
    return {
      description: data.content || '',
      image: getEventtiaThemeImage(data),
      uri: data.nodeAliasPath,
      title: data.title || '',
      virtual: data.isVirtual || false,
      id: data.nodeGuid || '',
      date: dates.join(' - '),
      address: data.address,
      coords,
    };
  }
}

export function createSafeTravels(
  widget: SafeTravelsWidget
): SafeTravelsListInterface {
  let title = widget.title || '';
  if (widget.hideTitle) {
    title = '';
  }

  return {
    className: CztWidgets.SAFE_TRAVELS,
    guid: widget.nodeGuid || 'unknown',
    title,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
  };
}

export function createCertifiedSubjects(
  widget: CertifiedSubjectsWidget
): CertifiedSubjectsListInterface {
  let title = widget.title || '';
  if (widget.hideTitle) {
    title = '';
  }

  return {
    className: CztWidgets.CERTIFIED_SUBJECTS,
    guid: widget.nodeGuid || 'unknown',
    title,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
  };
}

function getEventtiaThemeImage(page: EventtiaEventPage): ImageInterface {
  const theme = page.themes && page.themes.length > 0 ? page.themes[0] : null;
  const src =
    theme && theme.resourceKey
      ? urlTools.format(
          urlTools.parse(apiUrl + '/CZT/media/CZTAssets/Eventtia/', true)
        ) +
        theme.resourceKey.replace(/\./g, '-') +
        '.jpg'
      : eventtiaFallback;
  return {
    src,
    alt: page.title,
    fallback: eventtiaFallback,
  };
}

export function createVenueFinder(
  widget: VenueFinderWidget
): VenueFinderInterface {
  let title = `${widget.title}`;

  if (widget.hideTitle) {
    title = '';
  }

  return {
    className: CztWidgets.VENUE_FINDER,
    guid: widget.nodeGuid || 'unknown',
    title,
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed,
  };
}

export function createNewsletterForm(
  widget: NewsletterFormWidget
): NewsletterFormInterface {
  return {
    className: CztWidgets.NEWSLETTER,
    guid: widget.nodeGuid || 'unknown',
  };
}

export function createPanorama(
  widget: PanoramicPhotoWidget
): PanoramaInterface {
  let title = widget.title || '';

  if (widget.hideTitle) {
    title = '';
  }

  return {
    className: CztWidgets.PANORAMA,
    caption: widget.imageCaption,
    guid: widget.nodeGuid || 'unknown',
    isBottomSpacingCollapsed: widget.isBottomSpacingCollapsed || false,
    isTopSpacingCollapsed: widget.isTopSpacingCollapsed || false,
    image: widget.image || '',
    rotate: widget.automaticRotation || false,
    title,
  };
}

export function getUrlParameterByName(
  name: string,
  url: string
): string | null {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getVideoId(src: string): string | undefined {
  const regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]+).*/;
  const matched = src.match(regex);
  if (!matched || matched.length < 1) {
    return undefined;
  }
  return matched[1];
}
