import { Component, Prop } from 'vue-property-decorator';
import { isGridWidget } from '~/app/core/apiClient';
import { GridWidget, Region } from '~/app/core/apiClient/api';
import { Button, Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { CztImage } from '~/utils/atoms/image';
import { MediaBackground } from '~/components/molecules';
import Brochure from '~/components/molecules/gridItem/Brochure';
import Classic from '~/components/molecules/gridItem/Classic';
import Extended from '~/components/molecules/gridItem/Extended';
import Simple from '~/components/molecules/gridItem/Simple';
import { Sizes } from '~/components/molecules/imageWrapper/ImageWrapper';
import { ImageTileInterface } from '~/components/molecules/imageTile/ImageTile';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { ThemeColors, ThemeRatios } from '~/utils/theme';
import { VueComponentMixin } from '~/utils/vue-component';
import { createGridItem } from '~/utils/views/components';
import { CztWidgets } from '~/utils/views/widgets';
import WidgetStyleEnum = GridWidget.WidgetStyleEnum;

import style from './GridList.scss';
import { getModule } from 'vuex-module-decorators';
import RouterModule from '~/app/core/store/modules/RouterModule';
import { ImageFilterMixinInterface } from '~/mixins';
import { LocaleMessage } from 'vue-i18n';

export type GridItem = ImageTileInterface &
  ImageFilterMixinInterface & {
    description: string;
    date?: string;
    endDate?: string;
    perex?: string;
    link: string;
    linkText: string;
    title: string;
    location?: Region;
  };

export interface GridListInterface {
  buttonText?: string;
  className: CztWidgets;
  guid: string;
  image?: CztImage;
  isBottomSpacingCollapsed?: boolean;
  isTopSpacingCollapsed?: boolean;
  items: GridItem[];
  imageAspectRatio?: ThemeRatios;
  link?: string;
  maxItems?: number;
  nextPageCount?: number;
  pageSize?: number;
  overlayColor?: ThemeColors;
  type: WidgetStyleEnum;
  title: string | LocaleMessage;
}

export function isGrid(data: any): data is GridListInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.GRID &&
    typeof data.guid === 'string' &&
    typeof data.type === 'string'
  );
}

const sizes: Sizes[] = [
  {
    size: 100,
    unit: 'vw',
  },
];

const rootClass = 'czt-grid-list';

@Component({
  style,
})
export default class GridList
  extends VueComponentMixin<GridListInterface, PrefetchComponent>(Prefetch)
  implements GridListInterface {
  @Prop({ required: true, type: Array })
  public items!: GridItem[];

  @Prop({ required: true, type: String })
  public title!: string | LocaleMessage;

  @Prop({ type: String, required: true })
  public guid!: string;

  @Prop()
  public image?: CztImage;

  @Prop()
  public imageAspectRatio?: ThemeRatios;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ type: String })
  public link?: string;

  @Prop()
  public overlayColor?: ThemeColors;

  @Prop({ default: 0, type: Number })
  public maxItems!: number;

  /**
   * This is the number of items in the next page that came with initial request
   */
  @Prop({ default: 0, type: Number })
  public nextPageCount!: number;

  @Prop({ default: 0, type: Number })
  public pageSize!: number;

  @Prop({ type: String })
  public buttonText?: string;

  @Prop({ type: String })
  public type!: WidgetStyleEnum;

  public className = CztWidgets.GRID;

  protected rightColumn: boolean = false;

  /**
   * Extra property for storage of items loaded through load more button
   * as we cannot update the parent 'items' prop
   *
   * These items are concatenated in itemsCollection getter
   */
  protected loadedItems: GridItem[] = [];

  /**
   * We are starting on page 1
   */
  protected pageNumber = 1;

  /**
   * Flag indicating we are downloading items from the backend
   */
  protected fetchingItems: boolean = false;

  /**
   * Whether it is possible to load more items in the grid
   */
  protected nextPage: boolean = true;

  protected routerModule!: RouterModule;

  /**
   * Collection of items passed on the page load and loaded through load more button
   */
  protected get itemsCollection(): GridItem[] {
    return this.items.concat(this.loadedItems);
  }

  public created() {
    if (this.nextPageCount === 0) {
      this.nextPage = false;
    }

    this.routerModule = getModule(RouterModule, this.$store);
  }

  public render() {
    if (this.image) {
      return (
        <MediaBackground
          class={rootClass}
          image={this.image}
          sizes={sizes}
          overlayColor={
            this.image && this.image.src
              ? this.overlayColor
                ? this.overlayColor
                : ThemeColors.PRIMARY
              : undefined
          }
        >
          {this.renderContent()}
        </MediaBackground>
      );
    } else {
      return <v-sheet class={rootClass}>{this.renderContent()}</v-sheet>;
    }
  }

  protected renderContent() {
    const containerClasses = ['czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-bottom');
    }

    return (
      <v-container class={containerClasses.join(' ')}>
        {!this.rightColumn && <v-row>{this.getHeadline()}</v-row>}
        <v-row class='ma-0 pa-0'>
          <v-col class='py-0'>
            <v-row class={`${rootClass}__container`}>
              {this.getGridItems()}
            </v-row>
            {this.renderLoadMoreButton()}
          </v-col>
          {this.getSideColumn()}
        </v-row>
        {this.getButton()}
      </v-container>
    );
  }

  protected getHeadline() {
    if (this.items.length === 0) {
      return;
    }

    return (
      <v-col class='py-0'>
        <Headline
          underscore
          align={this.rightColumn ? Align.RIGHT : Align.LEFT}
          level={2}
          light={!!this.image?.src}
        >
          {this.title}
        </Headline>
      </v-col>
    );
  }

  protected getButton() {
    if (!this.link) {
      return;
    }
    return (
      <v-col cols='auto' class='py-0 text-right'>
        <Button url={this.link}>
          {this.buttonText
            ? this.buttonText
            : this.$t('app.gridList.buttonText')}
        </Button>
      </v-col>
    );
  }

  protected getSideColumn() {
    if (this.rightColumn)
      return (
        <v-col class='py-0' cols='3'>
          <v-row
            class='flex-column fill-height'
            justify-content='space-between'
          >
            {[this.getHeadline(), this.getButton()]}
            <v-col cols='auto' class='py-0 text-right'>
              <Button>
                {this.buttonText
                  ? this.buttonText
                  : this.$t('app.gridList.buttonText')}
              </Button>
            </v-col>
          </v-row>
        </v-col>
      );
  }

  protected getGridItems() {
    if (
      this.type === WidgetStyleEnum.Classic ||
      this.type === WidgetStyleEnum.Compact
    ) {
      return this.itemsCollection.map((item, index) => (
        <Classic
          item={item}
          key={index + item.title}
          compact={this.type === WidgetStyleEnum.Compact}
        />
      ));
    } else if (this.type === WidgetStyleEnum.Simplelist) {
      return this.itemsCollection.map((item, index) => (
        <Simple item={item} key={index + item.title} />
      ));
    } else if (this.type === WidgetStyleEnum.Extended) {
      return this.itemsCollection.map((item, index) => (
        <Extended item={item} key={index + item.title} />
      ));
    } else if (this.type === WidgetStyleEnum.Brochures) {
      return this.itemsCollection.map((item, index) => (
        <Brochure
          item={item}
          key={index + item.title}
          imageRatio={this.imageAspectRatio}
        />
      ));
    }
  }

  protected renderLoadMoreButton() {
    if (this.pageSize < 1 || this.nextPageCount < 1) {
      // Do not render the load more button if paging is disabled
      return;
    }

    return (
      <v-row no-gutters justify='center' class='py-3'>
        <Button
          disabled={!this.nextPage}
          loading={this.fetchingItems}
          onClick={() => {
            if (this.fetchingItems) {
              return;
            }

            this.fetchingItems = true;
            this.pageNumber++;

            this.$api
              .widgets()
              .widgetsGetWidgetByGuid(
                this.guid,
                this.pageNumber,
                this.routerModule.resource
                  ? this.routerModule.resource.guid
                  : undefined,
                this.$i18n.locale,
                CztWidgets.GRID
              )
              .then((widget) => {
                if (isGridWidget(widget)) {
                  if (widget.items && widget.items.length > 0) {
                    widget.items.forEach((item) => {
                      this.loadedItems.push(createGridItem(item));
                    });

                    if (
                      !widget.itemsCountNextPage ||
                      widget.itemsCountNextPage < 1
                    ) {
                      this.nextPage = false;
                    }
                  } else {
                    this.nextPage = false;
                  }
                }
              })
              .catch(() => {
                this.pageNumber--;
              })
              .finally(() => {
                this.fetchingItems = false;
              });
          }}
        >
          {this.$t('app.common.loadMore')}
        </Button>
      </v-row>
    );
  }
}
