import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';

import CertifiedSubjectsLocalization from './certifiedSubjects';
import CommonLocalization from './common';
import CookiesLocalization from './cookies';
import EventLocalization from './event';
import EventsLocalization from './events';
import FooterLocalization from './footer';
import FormLocalization from './form';
import MenuLocalization from './menu';
import NewsletterLocalization from './newsletter';
import NotFoundLocalization from './notFound';
import PanoramaLocalization from './panorama';
import PlaceLocalization from './place';
import SafeTravelsLocalization from './safeTravels';
import SearchLocalization from './search';
import UrlLocalization from './url';
import VenuesLocalization from './venues';
import WebsAndAppsLocalization from './websAndApps';

const mergedLocalizations: LocaleMessages = deepmerge.all([
  CertifiedSubjectsLocalization,
  CommonLocalization,
  CookiesLocalization,
  EventLocalization,
  EventsLocalization,
  FooterLocalization,
  FormLocalization,
  MenuLocalization,
  NewsletterLocalization,
  NotFoundLocalization,
  PanoramaLocalization,
  PlaceLocalization,
  SafeTravelsLocalization,
  SearchLocalization,
  UrlLocalization,
  VenuesLocalization,
  WebsAndAppsLocalization,
]) as LocaleMessages;

export default mergedLocalizations;
