/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * CZTWebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./Configuration";

const BASE_PATH = "https://beta.czechtourism.cz/cms".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *  
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: string = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface BaseBizFormItem
 */
export interface BaseBizFormItem {
    /**
     * 
     * @type {string}
     * @memberof BaseBizFormItem
     */
    typeName?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseBizFormItem
     */
    formItemID?: number;
    /**
     * 
     * @type {Date}
     * @memberof BaseBizFormItem
     */
    formInserted?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseBizFormItem
     */
    formUpdated?: Date;
}

/**
 * 
 * @export
 * @interface BaseCustomTableItem
 */
export interface BaseCustomTableItem {
    /**
     * 
     * @type {string}
     * @memberof BaseCustomTableItem
     */
    typeName?: string;
}

/**
 * 
 * @export
 * @interface BaseNavigationItem
 */
export interface BaseNavigationItem {
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    cssClass?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseNavigationItem
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseNavigationItem
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BaseNavigationItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseNavigationItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseNavigationItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseNavigationItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BaseNavigationItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BaseNavigationItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseNavigationItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseNavigationItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BasePage
 */
export interface BasePage {
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    imageFilterType?: BasePage.ImageFilterTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BasePage
     */
    hideImageOnPage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    topLayerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePage
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePage
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof BasePage
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof BasePage
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof BasePage
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof BasePage
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BasePage
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasePage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof BasePage
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BasePage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePage
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePage
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BasePage
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BasePage
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePage
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    cultureCode?: string;
}

/**
 * @export
 * @namespace BasePage
 */
export namespace BasePage {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface BasePageItemWidgetCarouselWidgetItem
 */
export interface BasePageItemWidgetCarouselWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    ctaText?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    ctaLink?: string;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<CarouselWidgetItem>}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    items?: Array<CarouselWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetCarouselWidgetItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BasePageItemWidgetGridWidgetItem
 */
export interface BasePageItemWidgetGridWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    ctaText?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    ctaLink?: string;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<GridWidgetItem>}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    items?: Array<GridWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetGridWidgetItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BasePageItemWidgetSliderWidgetItem
 */
export interface BasePageItemWidgetSliderWidgetItem {
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    ctaText?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    ctaLink?: string;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<SliderWidgetItem>}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    items?: Array<SliderWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetSliderWidgetItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BasePageItemWidgetTabbedContentWidgetItem
 */
export interface BasePageItemWidgetTabbedContentWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    ctaText?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    ctaLink?: string;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<TabbedContentWidgetItem>}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    items?: Array<TabbedContentWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BasePageItemWidgetTabbedContentWidgetItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BaseSearchItem
 */
export interface BaseSearchItem {
    /**
     * 
     * @type {string}
     * @memberof BaseSearchItem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseSearchItem
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseSearchItem
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseSearchItem
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseSearchItem
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseSearchItem
     */
    score?: number;
}

/**
 * 
 * @export
 * @interface BaseWidget
 */
export interface BaseWidget {
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof BaseWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BaseWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidget
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BaseWidgetItem
 */
export interface BaseWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BaseWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItem
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface BreadcrumbItem
 */
export interface BreadcrumbItem {
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbItem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbItem
     */
    url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BreadcrumbItem
     */
    alternativeUrls?: Array<string>;
}

/**
 * 
 * @export
 * @interface EvAccount
 */
export interface EvAccount {
    /**
     * 
     * @type {EvData}
     * @memberof EvAccount
     */
    data?: EvData;
}

/**
 * 
 * @export
 * @interface EvAccountFonts
 */
export interface EvAccountFonts {
    /**
     * 
     * @type {Array<EvData>}
     * @memberof EvAccountFonts
     */
    data?: Array<EvData>;
}

/**
 * 
 * @export
 * @interface EvAdministrativeArea
 */
export interface EvAdministrativeArea {
    /**
     * 
     * @type {any}
     * @memberof EvAdministrativeArea
     */
    data?: any;
}

/**
 * 
 * @export
 * @interface EvAttendeeTypes
 */
export interface EvAttendeeTypes {
    /**
     * 
     * @type {Array<EvData>}
     * @memberof EvAttendeeTypes
     */
    data?: Array<EvData>;
}

/**
 * 
 * @export
 * @interface EvAttributes
 */
export interface EvAttributes {
    /**
     * 
     * @type {Date}
     * @memberof EvAttributes
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EvAttributes
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EvAttributes
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EvAttributes
     */
    updatedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof EvAttributes
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EvAttributes
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EvAttributes
     */
    eventUri?: string;
    /**
     * 
     * @type {EvLogo}
     * @memberof EvAttributes
     */
    logo?: EvLogo;
    /**
     * 
     * @type {EvCoordinates}
     * @memberof EvAttributes
     */
    coordinates?: EvCoordinates;
    /**
     * 
     * @type {string}
     * @memberof EvAttributes
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof EvAttributes
     */
    timezone?: string;
    /**
     * 
     * @type {string}
     * @memberof EvAttributes
     */
    timeFormat?: string;
    /**
     * 
     * @type {string}
     * @memberof EvAttributes
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EvAttributes
     */
    defaultLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof EvAttributes
     */
    currency?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EvAttributes
     */
    whiteLabel?: boolean;
    /**
     * 
     * @type {EvWhiteLabelImages}
     * @memberof EvAttributes
     */
    whiteLabelImages?: EvWhiteLabelImages;
    /**
     * 
     * @type {any}
     * @memberof EvAttributes
     */
    googleAnalyticsTrackingCode?: any;
    /**
     * 
     * @type {any}
     * @memberof EvAttributes
     */
    googleTagManagerTrackingCode?: any;
    /**
     * 
     * @type {string}
     * @memberof EvAttributes
     */
    senderEmail?: string;
    /**
     * 
     * @type {EvBannerImage}
     * @memberof EvAttributes
     */
    bannerImage?: EvBannerImage;
    /**
     * 
     * @type {string}
     * @memberof EvAttributes
     */
    staticMap?: string;
    /**
     * 
     * @type {string}
     * @memberof EvAttributes
     */
    website?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EvAttributes
     */
    isVirtual?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EvAttributes
     */
    virtualTimezone?: string;
    /**
     * 
     * @type {any}
     * @memberof EvAttributes
     */
    clonedFromId?: any;
    /**
     * 
     * @type {number}
     * @memberof EvAttributes
     */
    budget?: number;
    /**
     * 
     * @type {number}
     * @memberof EvAttributes
     */
    totalAttendees?: number;
    /**
     * 
     * @type {string}
     * @memberof EvAttributes
     */
    apiKey?: string;
    /**
     * 
     * @type {EvFields}
     * @memberof EvAttributes
     */
    fields?: EvFields;
}

/**
 * 
 * @export
 * @interface EvBannerImage
 */
export interface EvBannerImage {
    /**
     * 
     * @type {any}
     * @memberof EvBannerImage
     */
    filename?: any;
    /**
     * 
     * @type {string}
     * @memberof EvBannerImage
     */
    thumb?: string;
    /**
     * 
     * @type {string}
     * @memberof EvBannerImage
     */
    small?: string;
    /**
     * 
     * @type {string}
     * @memberof EvBannerImage
     */
    medium?: string;
    /**
     * 
     * @type {string}
     * @memberof EvBannerImage
     */
    large?: string;
}

/**
 * 
 * @export
 * @interface EvBusinessConferences
 */
export interface EvBusinessConferences {
    /**
     * 
     * @type {Array<any>}
     * @memberof EvBusinessConferences
     */
    data?: Array<any>;
}

/**
 * 
 * @export
 * @interface EvCategory
 */
export interface EvCategory {
    /**
     * 
     * @type {any}
     * @memberof EvCategory
     */
    data?: any;
}

/**
 * 
 * @export
 * @interface EvCoordinates
 */
export interface EvCoordinates {
    /**
     * 
     * @type {number}
     * @memberof EvCoordinates
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof EvCoordinates
     */
    lng?: number;
}

/**
 * 
 * @export
 * @interface EvCustomFields
 */
export interface EvCustomFields {
    /**
     * 
     * @type {Array<any>}
     * @memberof EvCustomFields
     */
    data?: Array<any>;
}

/**
 * 
 * @export
 * @interface EvData
 */
export interface EvData {
    /**
     * 
     * @type {string}
     * @memberof EvData
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EvData
     */
    type?: string;
}

/**
 * 
 * @export
 * @interface EvDefaultProfileFields
 */
export interface EvDefaultProfileFields {
    /**
     * 
     * @type {Array<EvData>}
     * @memberof EvDefaultProfileFields
     */
    data?: Array<EvData>;
}

/**
 * 
 * @export
 * @interface EvEvent
 */
export interface EvEvent {
    /**
     * 
     * @type {number}
     * @memberof EvEvent
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EvEvent
     */
    type?: string;
    /**
     * 
     * @type {EvAttributes}
     * @memberof EvEvent
     */
    attributes?: EvAttributes;
    /**
     * 
     * @type {EvRelationships}
     * @memberof EvEvent
     */
    relationships?: EvRelationships;
}

/**
 * 
 * @export
 * @interface EvExtraProfileFields
 */
export interface EvExtraProfileFields {
    /**
     * 
     * @type {Array<EvData>}
     * @memberof EvExtraProfileFields
     */
    data?: Array<EvData>;
}

/**
 * 
 * @export
 * @interface EvFields
 */
export interface EvFields {
}

/**
 * 
 * @export
 * @interface EvLogo
 */
export interface EvLogo {
    /**
     * 
     * @type {any}
     * @memberof EvLogo
     */
    filename?: any;
    /**
     * 
     * @type {string}
     * @memberof EvLogo
     */
    thumb?: string;
    /**
     * 
     * @type {string}
     * @memberof EvLogo
     */
    small?: string;
    /**
     * 
     * @type {string}
     * @memberof EvLogo
     */
    medium?: string;
    /**
     * 
     * @type {string}
     * @memberof EvLogo
     */
    large?: string;
}

/**
 * 
 * @export
 * @interface EvRelationships
 */
export interface EvRelationships {
    /**
     * 
     * @type {EvAttendeeTypes}
     * @memberof EvRelationships
     */
    attendeeTypes?: EvAttendeeTypes;
    /**
     * 
     * @type {EvBusinessConferences}
     * @memberof EvRelationships
     */
    businessConferences?: EvBusinessConferences;
    /**
     * 
     * @type {EvAccountFonts}
     * @memberof EvRelationships
     */
    accountFonts?: EvAccountFonts;
    /**
     * 
     * @type {EvCustomFields}
     * @memberof EvRelationships
     */
    customFields?: EvCustomFields;
    /**
     * 
     * @type {EvAccount}
     * @memberof EvRelationships
     */
    account?: EvAccount;
    /**
     * 
     * @type {EvCategory}
     * @memberof EvRelationships
     */
    category?: EvCategory;
    /**
     * 
     * @type {EvAdministrativeArea}
     * @memberof EvRelationships
     */
    administrativeArea?: EvAdministrativeArea;
    /**
     * 
     * @type {EvDefaultProfileFields}
     * @memberof EvRelationships
     */
    defaultProfileFields?: EvDefaultProfileFields;
    /**
     * 
     * @type {EvExtraProfileFields}
     * @memberof EvRelationships
     */
    extraProfileFields?: EvExtraProfileFields;
    /**
     * 
     * @type {EvStandReservationProductFields}
     * @memberof EvRelationships
     */
    standReservationProductFields?: EvStandReservationProductFields;
    /**
     * 
     * @type {EvStandCategories}
     * @memberof EvRelationships
     */
    standCategories?: EvStandCategories;
}

/**
 * 
 * @export
 * @interface EvStandCategories
 */
export interface EvStandCategories {
    /**
     * 
     * @type {Array<EvData>}
     * @memberof EvStandCategories
     */
    data?: Array<EvData>;
}

/**
 * 
 * @export
 * @interface EvStandReservationProductFields
 */
export interface EvStandReservationProductFields {
    /**
     * 
     * @type {Array<EvData>}
     * @memberof EvStandReservationProductFields
     */
    data?: Array<EvData>;
}

/**
 * 
 * @export
 * @interface EvWhiteLabelImages
 */
export interface EvWhiteLabelImages {
}

/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    href?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    rel?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    method?: string;
}

/**
 * 
 * @export
 * @interface NewsletterContact
 */
export interface NewsletterContact {
    /**
     * 
     * @type {string}
     * @memberof NewsletterContact
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterContact
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterContact
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterContact
     */
    reCaptchaToken?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewsletterContact
     */
    newsletterNames: Array<string>;
}

/**
 * 
 * @export
 * @interface NewsletterInfo
 */
export interface NewsletterInfo {
    /**
     * 
     * @type {string}
     * @memberof NewsletterInfo
     */
    newsletterGUID?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterInfo
     */
    newsletterDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterInfo
     */
    newsletterName?: string;
}

/**
 * 
 * @export
 * @interface ResourceString
 */
export interface ResourceString {
    /**
     * 
     * @type {string}
     * @memberof ResourceString
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceString
     */
    resourceText?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceString
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface SearchResults
 */
export interface SearchResults {
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<BaseSearchItem>}
     * @memberof SearchResults
     */
    items?: Array<BaseSearchItem>;
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResults
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchResults
     */
    searchedTerm?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResults
     */
    searchQueryUsed?: string;
}

/**
 * 
 * @export
 * @interface SiteCulture
 */
export interface SiteCulture {
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureName?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureShortName?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureShortNameNative?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureLangCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteCulture
     */
    cultureCountry?: string;
}

/**
 * 
 * @export
 * @interface VotingResponse
 */
export interface VotingResponse {
    /**
     * 
     * @type {boolean}
     * @memberof VotingResponse
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VotingResponse
     */
    message?: string;
}

/**
 * 
 * @export
 * @interface ArticlePage
 */
export interface ArticlePage {
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    imageFilterType?: ArticlePage.ImageFilterTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePage
     */
    hideImageOnPage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    topLayerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePage
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePage
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ArticlePage
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ArticlePage
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ArticlePage
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof ArticlePage
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof ArticlePage
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticlePage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof ArticlePage
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePage
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePage
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePage
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof ArticlePage
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArticlePage
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticlePage
     */
    moreInfo?: string;
    /**
     * 
     * @type {Date}
     * @memberof ArticlePage
     */
    date?: Date;
    /**
     * 
     * @type {Array<Category>}
     * @memberof ArticlePage
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof ArticlePage
     */
    subCategories?: Array<SubCategory>;
}

/**
 * @export
 * @namespace ArticlePage
 */
export namespace ArticlePage {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface AutomatedTranslationVoting
 */
export interface AutomatedTranslationVoting {
    /**
     * 
     * @type {string}
     * @memberof AutomatedTranslationVoting
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof AutomatedTranslationVoting
     */
    nodeGUID: string;
    /**
     * 
     * @type {string}
     * @memberof AutomatedTranslationVoting
     */
    documentCulture: string;
    /**
     * 
     * @type {string}
     * @memberof AutomatedTranslationVoting
     */
    status: AutomatedTranslationVoting.StatusEnum;
}

/**
 * @export
 * @namespace AutomatedTranslationVoting
 */
export namespace AutomatedTranslationVoting {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Unknown = <any> 'Unknown',
        Dislike = <any> 'Dislike',
        Like = <any> 'Like'
    }
}

/**
 * 
 * @export
 * @interface BaseWidgetItemWithPageSelection
 */
export interface BaseWidgetItemWithPageSelection {
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof BaseWidgetItemWithPageSelection
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseWidgetItemWithPageSelection
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseWidgetItemWithPageSelection
     */
    url?: string;
}

/**
 * 
 * @export
 * @interface CarouselWidget
 */
export interface CarouselWidget {
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    ctaText?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    ctaLink?: string;
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<CarouselWidgetItem>}
     * @memberof CarouselWidget
     */
    items?: Array<CarouselWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof CarouselWidget
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof CarouselWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof CarouselWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidget
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    categoryGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface CertifiedSubjectsOfQuality
 */
export interface CertifiedSubjectsOfQuality {
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsOfQuality
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsOfQuality
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsOfQuality
     */
    webUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsOfQuality
     */
    area?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsOfQuality
     */
    region?: string;
}

/**
 * 
 * @export
 * @interface CertifiedSubjectsWidget
 */
export interface CertifiedSubjectsWidget {
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof CertifiedSubjectsWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CertifiedSubjectsWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CertifiedSubjectsWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof CertifiedSubjectsWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof CertifiedSubjectsWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CertifiedSubjectsWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CertifiedSubjectsWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CertifiedSubjectsWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CertifiedSubjectsWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof CertifiedSubjectsWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CertifiedSubjectsWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CertifiedSubjectsWidget
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface EventCategory
 */
export interface EventCategory {
    /**
     * 
     * @type {string}
     * @memberof EventCategory
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCategory
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCategory
     */
    eventCategoryGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCategory
     */
    eventCategoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCategory
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface EventPage
 */
export interface EventPage {
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    imageFilterType?: EventPage.ImageFilterTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EventPage
     */
    hideImageOnPage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    topLayerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventPage
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventPage
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventPage
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventPage
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventPage
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventPage
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EventPage
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof EventPage
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof EventPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPage
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPage
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof EventPage
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof EventPage
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventPage
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    cultureCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof EventPage
     */
    startDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventPage
     */
    endDateTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof EventPage
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EventPage
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    organizer?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    webUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EventPage
     */
    web2Url?: string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof EventPage
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof EventPage
     */
    subCategories?: Array<SubCategory>;
}

/**
 * @export
 * @namespace EventPage
 */
export namespace EventPage {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface EventtiaEvent
 */
export interface EventtiaEvent {
    /**
     * 
     * @type {string}
     * @memberof EventtiaEvent
     */
    typeName?: string;
    /**
     * 
     * @type {EvEvent}
     * @memberof EventtiaEvent
     */
    eventJson?: EvEvent;
}

/**
 * 
 * @export
 * @interface EventtiaEventPackage
 */
export interface EventtiaEventPackage {
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPackage
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPackage
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPackage
     */
    eventtiaEventPackageGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPackage
     */
    eventtiaEventPackageName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPackage
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface EventtiaEventPage
 */
export interface EventtiaEventPage {
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    imageFilterType?: EventtiaEventPage.ImageFilterTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventPage
     */
    hideImageOnPage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    topLayerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventPage
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventPage
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventtiaEventPage
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventtiaEventPage
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventtiaEventPage
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof EventtiaEventPage
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EventtiaEventPage
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventtiaEventPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof EventtiaEventPage
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventPage
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventPage
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventPage
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof EventtiaEventPage
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventPage
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    cultureCode?: string;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventPage
     */
    eventtiaID?: number;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventPage
     */
    eventtiaCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventPage
     */
    eventtiaUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    eventUri?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventPage
     */
    isVirtual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventPage
     */
    active?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventPage
     */
    startDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventPage
     */
    endDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventPage
     */
    localStartDateTimeOffset?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventPage
     */
    localStartDateTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventPage
     */
    isLocalStartDateTimeDST?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventPage
     */
    localEndDateTimeOffset?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventPage
     */
    localEndDateTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventPage
     */
    isLocalEndDateTimeDST?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    localStartDateFormatted?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    localEndDateFormatted?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    logo?: string;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventPage
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventPage
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    timezone?: string;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventPage
     */
    timezoneOffset?: number;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventPage
     */
    timezoneOffsetDST?: number;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    virtualTimezone?: string;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventPage
     */
    virtualTimezoneOffset?: number;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventPage
     */
    virtualTimezoneOffsetDST?: number;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    timeFormat?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    senderEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventPage
     */
    defaultLanguage?: string;
    /**
     * 
     * @type {EvEvent}
     * @memberof EventtiaEventPage
     */
    originalData?: EvEvent;
    /**
     * 
     * @type {Array<EventtiaEventType>}
     * @memberof EventtiaEventPage
     */
    types?: Array<EventtiaEventType>;
    /**
     * 
     * @type {Array<EventtiaEventTheme>}
     * @memberof EventtiaEventPage
     */
    themes?: Array<EventtiaEventTheme>;
    /**
     * 
     * @type {Array<EventtiaEventPackage>}
     * @memberof EventtiaEventPage
     */
    packages?: Array<EventtiaEventPackage>;
}

/**
 * @export
 * @namespace EventtiaEventPage
 */
export namespace EventtiaEventPage {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface EventtiaEventTheme
 */
export interface EventtiaEventTheme {
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventTheme
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventTheme
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventTheme
     */
    eventtiaEventThemeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventTheme
     */
    eventtiaEventThemeName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventTheme
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface EventtiaEventType
 */
export interface EventtiaEventType {
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventType
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventType
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventType
     */
    eventtiaEventTypeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventType
     */
    eventtiaEventTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventType
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface EventtiaEventsWidget
 */
export interface EventtiaEventsWidget {
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventsWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventsWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventsWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof EventtiaEventsWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventsWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventsWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventsWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventsWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventsWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof EventtiaEventsWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventtiaEventsWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventsWidget
     */
    filterByStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventtiaEventsWidget
     */
    filterByEndDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    filterByTypeGuids?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    filterByThemeGuids?: string;
    /**
     * 
     * @type {string}
     * @memberof EventtiaEventsWidget
     */
    filterByPackageGuids?: string;
    /**
     * 
     * @type {Array<EventtiaEventPage>}
     * @memberof EventtiaEventsWidget
     */
    items?: Array<EventtiaEventPage>;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventsWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventsWidget
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventsWidget
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventsWidget
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventsWidget
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventsWidget
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof EventtiaEventsWidget
     */
    itemsCountTotal?: number;
}

/**
 * 
 * @export
 * @interface FaqWidget
 */
export interface FaqWidget {
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof FaqWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof FaqWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidget
     */
    showInRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidget
     */
    backgroundImage?: string;
    /**
     * 
     * @type {number}
     * @memberof FaqWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<BaseWidgetItem>}
     * @memberof FaqWidget
     */
    items?: Array<BaseWidgetItem>;
}

/**
 * 
 * @export
 * @interface FaqWidgetItem
 */
export interface FaqWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FaqWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof FaqWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FaqWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof FaqWidgetItem
     */
    content?: string;
}

/**
 * 
 * @export
 * @interface GalleryWidget
 */
export interface GalleryWidget {
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof GalleryWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof GalleryWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidget
     */
    widgetStyle?: GalleryWidget.WidgetStyleEnum;
    /**
     * 
     * @type {number}
     * @memberof GalleryWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<BaseWidgetItem>}
     * @memberof GalleryWidget
     */
    items?: Array<BaseWidgetItem>;
}

/**
 * @export
 * @namespace GalleryWidget
 */
export namespace GalleryWidget {
    /**
     * @export
     * @enum {string}
     */
    export enum WidgetStyleEnum {
        Slider = <any> 'slider',
        Grid = <any> 'grid'
    }
}

/**
 * 
 * @export
 * @interface GalleryWidgetItem
 */
export interface GalleryWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GalleryWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof GalleryWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GalleryWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof GalleryWidgetItem
     */
    imageFilterType?: GalleryWidgetItem.ImageFilterTypeEnum;
}

/**
 * @export
 * @namespace GalleryWidgetItem
 */
export namespace GalleryWidgetItem {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface GridWidget
 */
export interface GridWidget {
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    ctaText?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    ctaLink?: string;
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<GridWidgetItem>}
     * @memberof GridWidget
     */
    items?: Array<GridWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof GridWidget
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof GridWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof GridWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof GridWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    backgroundImage?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    widgetStyle?: GridWidget.WidgetStyleEnum;
    /**
     * 
     * @type {string}
     * @memberof GridWidget
     */
    itemImageAspectRatio?: string;
}

/**
 * @export
 * @namespace GridWidget
 */
export namespace GridWidget {
    /**
     * @export
     * @enum {string}
     */
    export enum WidgetStyleEnum {
        Classic = <any> 'classic',
        Compact = <any> 'compact',
        Extended = <any> 'extended',
        Brochures = <any> 'brochures',
        Simplelist = <any> 'simplelist'
    }
}

/**
 * 
 * @export
 * @interface HtmlContentWidget
 */
export interface HtmlContentWidget {
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlContentWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlContentWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlContentWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof HtmlContentWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof HtmlContentWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HtmlContentWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HtmlContentWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HtmlContentWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlContentWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof HtmlContentWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlContentWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HtmlContentWidget
     */
    showInRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    imagePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    imageCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    imageLinkUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof HtmlContentWidget
     */
    themeStyle?: HtmlContentWidget.ThemeStyleEnum;
}

/**
 * @export
 * @namespace HtmlContentWidget
 */
export namespace HtmlContentWidget {
    /**
     * @export
     * @enum {string}
     */
    export enum ThemeStyleEnum {
        None = <any> 'none',
        Dark = <any> 'dark'
    }
}

/**
 * 
 * @export
 * @interface IframeWidget
 */
export interface IframeWidget {
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof IframeWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IframeWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IframeWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof IframeWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof IframeWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IframeWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IframeWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IframeWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof IframeWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof IframeWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IframeWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IframeWidget
     */
    showInRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IframeWidget
     */
    url?: string;
    /**
     * 
     * @type {number}
     * @memberof IframeWidget
     */
    height?: number;
}

/**
 * 
 * @export
 * @interface NavigationFolder
 */
export interface NavigationFolder {
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    cssClass?: string;
    /**
     * 
     * @type {number}
     * @memberof NavigationFolder
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof NavigationFolder
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof NavigationFolder
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NavigationFolder
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NavigationFolder
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NavigationFolder
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationFolder
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof NavigationFolder
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationFolder
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationFolder
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationFolder
     */
    isHorizontalMenu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationFolder
     */
    isImageMenu?: boolean;
    /**
     * 
     * @type {Array<BaseNavigationItem>}
     * @memberof NavigationFolder
     */
    items: Array<BaseNavigationItem>;
}

/**
 * 
 * @export
 * @interface NavigationItem
 */
export interface NavigationItem {
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    cssClass?: string;
    /**
     * 
     * @type {number}
     * @memberof NavigationItem
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof NavigationItem
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof NavigationItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NavigationItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NavigationItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NavigationItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof NavigationItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NavigationItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    target?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof NavigationItem
     */
    url: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NavigationItem
     */
    alternativeUrls: Array<string>;
}

/**
 * 
 * @export
 * @interface NewsletterFormWidget
 */
export interface NewsletterFormWidget {
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterFormWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterFormWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterFormWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof NewsletterFormWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof NewsletterFormWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NewsletterFormWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NewsletterFormWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NewsletterFormWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterFormWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof NewsletterFormWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterFormWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterFormWidget
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    imageFilterType?: Page.ImageFilterTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    hideImageOnPage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    topLayerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    showRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    menuCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    menuItemGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    documentPageTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    documentPageDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    documentPageKeywords?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    showAutomatedTranslationVoting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    automatedTranslationMessage?: string;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof Page
     */
    rightColWidgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof Page
     */
    widgetsSharedBefore?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof Page
     */
    widgets?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<BaseWidget>}
     * @memberof Page
     */
    widgetsSharedAfter?: Array<BaseWidget>;
    /**
     * 
     * @type {Array<Link>}
     * @memberof Page
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Page
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {Array<BreadcrumbItem>}
     * @memberof Page
     */
    breadcrumbs?: Array<BreadcrumbItem>;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    nearestNavigationNodeAliasPath?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof Page
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Page
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Page
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Page
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof Page
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    cultureCode?: string;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Page
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof Page
     */
    subCategories?: Array<SubCategory>;
}

/**
 * @export
 * @namespace Page
 */
export namespace Page {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface PanoramicPhotoWidget
 */
export interface PanoramicPhotoWidget {
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramicPhotoWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramicPhotoWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramicPhotoWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof PanoramicPhotoWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof PanoramicPhotoWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PanoramicPhotoWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PanoramicPhotoWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PanoramicPhotoWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramicPhotoWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof PanoramicPhotoWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramicPhotoWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    imageCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramicPhotoWidget
     */
    imageFilterType?: PanoramicPhotoWidget.ImageFilterTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramicPhotoWidget
     */
    automaticRotation?: boolean;
}

/**
 * @export
 * @namespace PanoramicPhotoWidget
 */
export namespace PanoramicPhotoWidget {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    regionGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    regionName?: string;
}

/**
 * 
 * @export
 * @interface SafeTravelsStampItem
 */
export interface SafeTravelsStampItem {
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsStampItem
     */
    typeName?: string;
    /**
     * 
     * @type {number}
     * @memberof SafeTravelsStampItem
     */
    formItemID?: number;
    /**
     * 
     * @type {Date}
     * @memberof SafeTravelsStampItem
     */
    formInserted?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SafeTravelsStampItem
     */
    formUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsStampItem
     */
    nazevSpolecnosti?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsStampItem
     */
    nazevProvozovny?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsStampItem
     */
    ico?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsStampItem
     */
    ulice?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsStampItem
     */
    cisloPopisne?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsStampItem
     */
    mesto?: string;
    /**
     * 
     * @type {number}
     * @memberof SafeTravelsStampItem
     */
    psc?: number;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsStampItem
     */
    gpsSouradnice?: string;
    /**
     * 
     * @type {number}
     * @memberof SafeTravelsStampItem
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof SafeTravelsStampItem
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsStampItem
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsStampItem
     */
    telefon?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsStampItem
     */
    web?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsStampItem
     */
    oblastCestovnihoRuchu?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsStampItem
     */
    souhlasBezpecnostHygienaPodminky?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsStampItem
     */
    souhlasZpracovaniOsobnichUdaju?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsStampItem
     */
    zobrazeniNaWebech?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsStampItem
     */
    schvaleno?: boolean;
}

/**
 * 
 * @export
 * @interface SafeTravelsWidget
 */
export interface SafeTravelsWidget {
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof SafeTravelsWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof SafeTravelsWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SafeTravelsWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SafeTravelsWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SafeTravelsWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof SafeTravelsWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SafeTravelsWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SafeTravelsWidget
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface SearchItemPage
 */
export interface SearchItemPage {
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemPage
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchItemPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    documentCulture?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    nodeAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemPage
     */
    nodeAliasPath?: string;
}

/**
 * 
 * @export
 * @interface SliderWidget
 */
export interface SliderWidget {
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    ctaText?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    ctaLink?: string;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<SliderWidgetItem>}
     * @memberof SliderWidget
     */
    items?: Array<SliderWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof SliderWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof SliderWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {number}
     * @memberof SliderWidget
     */
    sliderDelaySec?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    showShareButtons?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    showInPageNav?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    useContentWidth?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidget
     */
    aspectRatio?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidget
     */
    loadFirstItemFromParentPage?: boolean;
}

/**
 * 
 * @export
 * @interface SocialChannelWidget
 */
export interface SocialChannelWidget {
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof SocialChannelWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SocialChannelWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SocialChannelWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof SocialChannelWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof SocialChannelWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SocialChannelWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SocialChannelWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SocialChannelWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SocialChannelWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof SocialChannelWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SocialChannelWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialChannelWidget
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface SubCategory
 */
export interface SubCategory {
    /**
     * 
     * @type {string}
     * @memberof SubCategory
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategory
     */
    resourceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategory
     */
    subCategoryGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategory
     */
    subCategoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategory
     */
    cultureCode?: string;
    /**
     * 
     * @type {Category}
     * @memberof SubCategory
     */
    category?: Category;
}

/**
 * 
 * @export
 * @interface TabbedContentWidget
 */
export interface TabbedContentWidget {
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    ctaText?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    ctaLink?: string;
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    maxItems?: number;
    /**
     * 
     * @type {Array<TabbedContentWidgetItem>}
     * @memberof TabbedContentWidget
     */
    items?: Array<TabbedContentWidgetItem>;
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    pageNum?: number;
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    itemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    itemsCountNextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof TabbedContentWidget
     */
    itemsCountTotal?: number;
    /**
     * 
     * @type {Array<Link>}
     * @memberof TabbedContentWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof TabbedContentWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    cultureCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidget
     */
    showInRightColumn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    backgroundImage?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    imagePosition?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    imageCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidget
     */
    imageLinkUrl?: string;
}

/**
 * 
 * @export
 * @interface TranslationStatistics
 */
export interface TranslationStatistics {
    /**
     * 
     * @type {string}
     * @memberof TranslationStatistics
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationStatistics
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationStatistics
     */
    languageCodeFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationStatistics
     */
    languageCodeTo?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationStatistics
     */
    translationService?: string;
}

/**
 * 
 * @export
 * @interface VenueFinderWidget
 */
export interface VenueFinderWidget {
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof VenueFinderWidget
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VenueFinderWidget
     */
    isTopSpacingCollapsed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VenueFinderWidget
     */
    isBottomSpacingCollapsed?: boolean;
    /**
     * 
     * @type {Array<Link>}
     * @memberof VenueFinderWidget
     */
    apiLinks?: Array<Link>;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof VenueFinderWidget
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VenueFinderWidget
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VenueFinderWidget
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VenueFinderWidget
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof VenueFinderWidget
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof VenueFinderWidget
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VenueFinderWidget
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFinderWidget
     */
    cultureCode?: string;
}

/**
 * 
 * @export
 * @interface CarouselWidgetItem
 */
export interface CarouselWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof CarouselWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarouselWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselWidgetItem
     */
    imageFilterType?: CarouselWidgetItem.ImageFilterTypeEnum;
    /**
     * 
     * @type {Region}
     * @memberof CarouselWidgetItem
     */
    region?: Region;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidgetItem
     */
    date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CarouselWidgetItem
     */
    dateTo?: Date;
}

/**
 * @export
 * @namespace CarouselWidgetItem
 */
export namespace CarouselWidgetItem {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface GridWidgetItem
 */
export interface GridWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof GridWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof GridWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GridWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    imageFilterType?: GridWidgetItem.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    urlText?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    widgetStyle?: GridWidgetItem.WidgetStyleEnum;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof GridWidgetItem
     */
    shortDesc?: string;
    /**
     * 
     * @type {Date}
     * @memberof GridWidgetItem
     */
    date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GridWidgetItem
     */
    dateTo?: Date;
}

/**
 * @export
 * @namespace GridWidgetItem
 */
export namespace GridWidgetItem {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum WidgetStyleEnum {
        Classic = <any> 'classic',
        Compact = <any> 'compact',
        Extended = <any> 'extended',
        Brochures = <any> 'brochures',
        Simplelist = <any> 'simplelist'
    }
}

/**
 * 
 * @export
 * @interface SearchItemArticlePage
 */
export interface SearchItemArticlePage {
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemArticlePage
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchItemArticlePage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemArticlePage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemArticlePage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    documentCulture?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    nodeAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemArticlePage
     */
    nodeAliasPath?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemArticlePage
     */
    date?: Date;
    /**
     * 
     * @type {Array<Category>}
     * @memberof SearchItemArticlePage
     */
    categories?: Array<Category>;
    /**
     * 
     * @type {Array<SubCategory>}
     * @memberof SearchItemArticlePage
     */
    subCategories?: Array<SubCategory>;
    /**
     * 
     * @type {Region}
     * @memberof SearchItemArticlePage
     */
    region?: Region;
}

/**
 * 
 * @export
 * @interface SearchItemEventPage
 */
export interface SearchItemEventPage {
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemEventPage
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchItemEventPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    documentCulture?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    nodeAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventPage
     */
    nodeAliasPath?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventPage
     */
    startDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventPage
     */
    endDateTime?: Date;
    /**
     * 
     * @type {Region}
     * @memberof SearchItemEventPage
     */
    region?: Region;
}

/**
 * 
 * @export
 * @interface SearchItemEventtiaEventPage
 */
export interface SearchItemEventtiaEventPage {
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemEventtiaEventPage
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchItemEventtiaEventPage
     */
    alternativeUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    nodeGuid?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventtiaEventPage
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventtiaEventPage
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    documentCulture?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    nodeAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    nodeAliasPath?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemEventtiaEventPage
     */
    eventtiaID?: number;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventtiaEventPage
     */
    eventtiaCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventtiaEventPage
     */
    eventtiaUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    eventUri?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchItemEventtiaEventPage
     */
    isVirtual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchItemEventtiaEventPage
     */
    active?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventtiaEventPage
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchItemEventtiaEventPage
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    logo?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchItemEventtiaEventPage
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchItemEventtiaEventPage
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    timezone?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    timeFormat?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    senderEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchItemEventtiaEventPage
     */
    defaultLanguage?: string;
    /**
     * 
     * @type {Array<EventtiaEventType>}
     * @memberof SearchItemEventtiaEventPage
     */
    types?: Array<EventtiaEventType>;
    /**
     * 
     * @type {Array<EventtiaEventTheme>}
     * @memberof SearchItemEventtiaEventPage
     */
    themes?: Array<EventtiaEventTheme>;
    /**
     * 
     * @type {Array<EventtiaEventPackage>}
     * @memberof SearchItemEventtiaEventPage
     */
    packages?: Array<EventtiaEventPackage>;
}

/**
 * 
 * @export
 * @interface SliderWidgetItem
 */
export interface SliderWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SliderWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof SliderWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidgetItem
     */
    hideTitle?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    subTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    imageSource?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    imageFilterType?: SliderWidgetItem.ImageFilterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    video?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SliderWidgetItem
     */
    showVideoControls?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    topLayerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    sideContent?: string;
    /**
     * 
     * @type {string}
     * @memberof SliderWidgetItem
     */
    urlText?: string;
    /**
     * 
     * @type {Region}
     * @memberof SliderWidgetItem
     */
    region?: Region;
}

/**
 * @export
 * @namespace SliderWidgetItem
 */
export namespace SliderWidgetItem {
    /**
     * @export
     * @enum {string}
     */
    export enum ImageFilterTypeEnum {
        None = <any> 'none',
        Darken = <any> 'darken',
        Softdarken = <any> 'softdarken'
    }
}

/**
 * 
 * @export
 * @interface TabbedContentWidgetItem
 */
export interface TabbedContentWidgetItem {
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    documentName: string;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidgetItem
     */
    documentCreatedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidgetItem
     */
    documentModifiedWhen?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidgetItem
     */
    documentPublishFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TabbedContentWidgetItem
     */
    documentPublishTo?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidgetItem
     */
    documentSearchExcluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    pageTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    nodeAlias: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    nodeAliasPath: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    permanentPath?: string;
    /**
     * 
     * @type {Array<SiteCulture>}
     * @memberof TabbedContentWidgetItem
     */
    availableInCultures?: Array<SiteCulture>;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    typeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TabbedContentWidgetItem
     */
    isPreview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    nodeGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    documentGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    cultureCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    sideContent?: string;
    /**
     * 
     * @type {string}
     * @memberof TabbedContentWidgetItem
     */
    urlText?: string;
}


/**
 * CategoriesApi - fetch parameter creator
 * @export
 */
export const CategoriesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetCategories(cultureCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/czt/api/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetCategory(categoryGuid: string, cultureCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'categoryGuid' is not null or undefined
            if (categoryGuid === null || categoryGuid === undefined) {
                throw new RequiredError('categoryGuid','Required parameter categoryGuid was null or undefined when calling categoriesGetCategory.');
            }
            const localVarPath = `/czt/api/category/{categoryGuid}`
                .replace(`{${"categoryGuid"}}`, encodeURIComponent(String(categoryGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetCategories(cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Category>> {
            const localVarFetchArgs = CategoriesApiFetchParamCreator(configuration).categoriesGetCategories(cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} categoryGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetCategory(categoryGuid: string, cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Category> {
            const localVarFetchArgs = CategoriesApiFetchParamCreator(configuration).categoriesGetCategory(categoryGuid, cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetCategories(cultureCode?: string, options?: any) {
            return CategoriesApiFp(configuration).categoriesGetCategories(cultureCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} categoryGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetCategory(categoryGuid: string, cultureCode?: string, options?: any) {
            return CategoriesApiFp(configuration).categoriesGetCategory(categoryGuid, cultureCode, options)(fetch, basePath);
        },
    };
};

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesGetCategories(cultureCode?: string, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesGetCategories(cultureCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} categoryGuid 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesGetCategory(categoryGuid: string, cultureCode?: string, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesGetCategory(categoryGuid, cultureCode, options)(this.fetch, this.basePath);
    }

}

/**
 * CertifiedSubjectsOfQualityApi - fetch parameter creator
 * @export
 */
export const CertifiedSubjectsOfQualityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certifiedSubjectsOfQualityGetItem(guid: string, options: any = {}): FetchArgs {
            // verify required parameter 'guid' is not null or undefined
            if (guid === null || guid === undefined) {
                throw new RequiredError('guid','Required parameter guid was null or undefined when calling certifiedSubjectsOfQualityGetItem.');
            }
            const localVarPath = `/czt/api/certifiedsubjects/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [area] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certifiedSubjectsOfQualityGetItems(name?: string, area?: string, region?: string, options: any = {}): FetchArgs {
            const localVarPath = `/czt/api/certifiedsubjects`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertifiedSubjectsOfQualityApi - functional programming interface
 * @export
 */
export const CertifiedSubjectsOfQualityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certifiedSubjectsOfQualityGetItem(guid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CertifiedSubjectsOfQuality> {
            const localVarFetchArgs = CertifiedSubjectsOfQualityApiFetchParamCreator(configuration).certifiedSubjectsOfQualityGetItem(guid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [area] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certifiedSubjectsOfQualityGetItems(name?: string, area?: string, region?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CertifiedSubjectsOfQuality>> {
            const localVarFetchArgs = CertifiedSubjectsOfQualityApiFetchParamCreator(configuration).certifiedSubjectsOfQualityGetItems(name, area, region, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CertifiedSubjectsOfQualityApi - factory interface
 * @export
 */
export const CertifiedSubjectsOfQualityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} guid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certifiedSubjectsOfQualityGetItem(guid: string, options?: any) {
            return CertifiedSubjectsOfQualityApiFp(configuration).certifiedSubjectsOfQualityGetItem(guid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [area] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        certifiedSubjectsOfQualityGetItems(name?: string, area?: string, region?: string, options?: any) {
            return CertifiedSubjectsOfQualityApiFp(configuration).certifiedSubjectsOfQualityGetItems(name, area, region, options)(fetch, basePath);
        },
    };
};

/**
 * CertifiedSubjectsOfQualityApi - object-oriented interface
 * @export
 * @class CertifiedSubjectsOfQualityApi
 * @extends {BaseAPI}
 */
export class CertifiedSubjectsOfQualityApi extends BaseAPI {
    /**
     * 
     * @param {string} guid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedSubjectsOfQualityApi
     */
    public certifiedSubjectsOfQualityGetItem(guid: string, options?: any) {
        return CertifiedSubjectsOfQualityApiFp(this.configuration).certifiedSubjectsOfQualityGetItem(guid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [name] 
     * @param {string} [area] 
     * @param {string} [region] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertifiedSubjectsOfQualityApi
     */
    public certifiedSubjectsOfQualityGetItems(name?: string, area?: string, region?: string, options?: any) {
        return CertifiedSubjectsOfQualityApiFp(this.configuration).certifiedSubjectsOfQualityGetItems(name, area, region, options)(this.fetch, this.basePath);
    }

}

/**
 * EventCategoriesApi - fetch parameter creator
 * @export
 */
export const EventCategoriesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCategoriesGetEventCategories(cultureCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/czt/api/eventcategories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventCategoryGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCategoriesGetEventCategory(eventCategoryGuid: string, cultureCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'eventCategoryGuid' is not null or undefined
            if (eventCategoryGuid === null || eventCategoryGuid === undefined) {
                throw new RequiredError('eventCategoryGuid','Required parameter eventCategoryGuid was null or undefined when calling eventCategoriesGetEventCategory.');
            }
            const localVarPath = `/czt/api/eventcategory/{eventCategoryGuid}`
                .replace(`{${"eventCategoryGuid"}}`, encodeURIComponent(String(eventCategoryGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventCategoriesApi - functional programming interface
 * @export
 */
export const EventCategoriesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCategoriesGetEventCategories(cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EventCategory>> {
            const localVarFetchArgs = EventCategoriesApiFetchParamCreator(configuration).eventCategoriesGetEventCategories(cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} eventCategoryGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCategoriesGetEventCategory(eventCategoryGuid: string, cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EventCategory> {
            const localVarFetchArgs = EventCategoriesApiFetchParamCreator(configuration).eventCategoriesGetEventCategory(eventCategoryGuid, cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * EventCategoriesApi - factory interface
 * @export
 */
export const EventCategoriesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCategoriesGetEventCategories(cultureCode?: string, options?: any) {
            return EventCategoriesApiFp(configuration).eventCategoriesGetEventCategories(cultureCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} eventCategoryGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventCategoriesGetEventCategory(eventCategoryGuid: string, cultureCode?: string, options?: any) {
            return EventCategoriesApiFp(configuration).eventCategoriesGetEventCategory(eventCategoryGuid, cultureCode, options)(fetch, basePath);
        },
    };
};

/**
 * EventCategoriesApi - object-oriented interface
 * @export
 * @class EventCategoriesApi
 * @extends {BaseAPI}
 */
export class EventCategoriesApi extends BaseAPI {
    /**
     * 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventCategoriesApi
     */
    public eventCategoriesGetEventCategories(cultureCode?: string, options?: any) {
        return EventCategoriesApiFp(this.configuration).eventCategoriesGetEventCategories(cultureCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} eventCategoryGuid 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventCategoriesApi
     */
    public eventCategoriesGetEventCategory(eventCategoryGuid: string, cultureCode?: string, options?: any) {
        return EventCategoriesApiFp(this.configuration).eventCategoriesGetEventCategory(eventCategoryGuid, cultureCode, options)(this.fetch, this.basePath);
    }

}

/**
 * EventtiaEventsApi - fetch parameter creator
 * @export
 */
export const EventtiaEventsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventtiaEventsGetEventtiaEventPackages(cultureCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/czt/api/eventtia/event/packages`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventtiaEventsGetEventtiaEventThemes(cultureCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/czt/api/eventtia/event/themes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventtiaEventsGetEventtiaEventTypes(cultureCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/czt/api/eventtia/event/types`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventtiaEventsApi - functional programming interface
 * @export
 */
export const EventtiaEventsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventtiaEventsGetEventtiaEventPackages(cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EventtiaEventPackage>> {
            const localVarFetchArgs = EventtiaEventsApiFetchParamCreator(configuration).eventtiaEventsGetEventtiaEventPackages(cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventtiaEventsGetEventtiaEventThemes(cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EventtiaEventTheme>> {
            const localVarFetchArgs = EventtiaEventsApiFetchParamCreator(configuration).eventtiaEventsGetEventtiaEventThemes(cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventtiaEventsGetEventtiaEventTypes(cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EventtiaEventType>> {
            const localVarFetchArgs = EventtiaEventsApiFetchParamCreator(configuration).eventtiaEventsGetEventtiaEventTypes(cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * EventtiaEventsApi - factory interface
 * @export
 */
export const EventtiaEventsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventtiaEventsGetEventtiaEventPackages(cultureCode?: string, options?: any) {
            return EventtiaEventsApiFp(configuration).eventtiaEventsGetEventtiaEventPackages(cultureCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventtiaEventsGetEventtiaEventThemes(cultureCode?: string, options?: any) {
            return EventtiaEventsApiFp(configuration).eventtiaEventsGetEventtiaEventThemes(cultureCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventtiaEventsGetEventtiaEventTypes(cultureCode?: string, options?: any) {
            return EventtiaEventsApiFp(configuration).eventtiaEventsGetEventtiaEventTypes(cultureCode, options)(fetch, basePath);
        },
    };
};

/**
 * EventtiaEventsApi - object-oriented interface
 * @export
 * @class EventtiaEventsApi
 * @extends {BaseAPI}
 */
export class EventtiaEventsApi extends BaseAPI {
    /**
     * 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventtiaEventsApi
     */
    public eventtiaEventsGetEventtiaEventPackages(cultureCode?: string, options?: any) {
        return EventtiaEventsApiFp(this.configuration).eventtiaEventsGetEventtiaEventPackages(cultureCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventtiaEventsApi
     */
    public eventtiaEventsGetEventtiaEventThemes(cultureCode?: string, options?: any) {
        return EventtiaEventsApiFp(this.configuration).eventtiaEventsGetEventtiaEventThemes(cultureCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventtiaEventsApi
     */
    public eventtiaEventsGetEventtiaEventTypes(cultureCode?: string, options?: any) {
        return EventtiaEventsApiFp(this.configuration).eventtiaEventsGetEventtiaEventTypes(cultureCode, options)(this.fetch, this.basePath);
    }

}

/**
 * EventtiaWrapperApi - fetch parameter creator
 * @export
 */
export const EventtiaWrapperApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} eventtiaPath 
         * @param {number} [cache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventtiaWrapperGet(eventtiaPath: string, cache?: number, options: any = {}): FetchArgs {
            // verify required parameter 'eventtiaPath' is not null or undefined
            if (eventtiaPath === null || eventtiaPath === undefined) {
                throw new RequiredError('eventtiaPath','Required parameter eventtiaPath was null or undefined when calling eventtiaWrapperGet.');
            }
            const localVarPath = `/czt/api/eventtiawrapper/get/{eventtiaPath}`
                .replace(`{${"eventtiaPath"}}`, encodeURIComponent(String(eventtiaPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventtiaWrapperApi - functional programming interface
 * @export
 */
export const EventtiaWrapperApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} eventtiaPath 
         * @param {number} [cache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventtiaWrapperGet(eventtiaPath: string, cache?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = EventtiaWrapperApiFetchParamCreator(configuration).eventtiaWrapperGet(eventtiaPath, cache, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * EventtiaWrapperApi - factory interface
 * @export
 */
export const EventtiaWrapperApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} eventtiaPath 
         * @param {number} [cache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventtiaWrapperGet(eventtiaPath: string, cache?: number, options?: any) {
            return EventtiaWrapperApiFp(configuration).eventtiaWrapperGet(eventtiaPath, cache, options)(fetch, basePath);
        },
    };
};

/**
 * EventtiaWrapperApi - object-oriented interface
 * @export
 * @class EventtiaWrapperApi
 * @extends {BaseAPI}
 */
export class EventtiaWrapperApi extends BaseAPI {
    /**
     * 
     * @param {string} eventtiaPath 
     * @param {number} [cache] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventtiaWrapperApi
     */
    public eventtiaWrapperGet(eventtiaPath: string, cache?: number, options?: any) {
        return EventtiaWrapperApiFp(this.configuration).eventtiaWrapperGet(eventtiaPath, cache, options)(this.fetch, this.basePath);
    }

}

/**
 * MeatSpaceWrapperApi - fetch parameter creator
 * @export
 */
export const MeatSpaceWrapperApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} meatSpacePath 
         * @param {number} [cache] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meatSpaceWrapperGet(meatSpacePath: string, cache?: number, lang?: string, options: any = {}): FetchArgs {
            // verify required parameter 'meatSpacePath' is not null or undefined
            if (meatSpacePath === null || meatSpacePath === undefined) {
                throw new RequiredError('meatSpacePath','Required parameter meatSpacePath was null or undefined when calling meatSpaceWrapperGet.');
            }
            const localVarPath = `/czt/api/meatspacewrapper/get/{meatSpacePath}`
                .replace(`{${"meatSpacePath"}}`, encodeURIComponent(String(meatSpacePath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} meatSpacePath 
         * @param {string} data 
         * @param {string} [lang] 
         * @param {string} [recaptchaToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meatSpaceWrapperPost(meatSpacePath: string, data: string, lang?: string, recaptchaToken?: string, options: any = {}): FetchArgs {
            // verify required parameter 'meatSpacePath' is not null or undefined
            if (meatSpacePath === null || meatSpacePath === undefined) {
                throw new RequiredError('meatSpacePath','Required parameter meatSpacePath was null or undefined when calling meatSpaceWrapperPost.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling meatSpaceWrapperPost.');
            }
            const localVarPath = `/czt/api/meatspacewrapper/post/{meatSpacePath}`
                .replace(`{${"meatSpacePath"}}`, encodeURIComponent(String(meatSpacePath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (recaptchaToken !== undefined) {
                localVarQueryParameter['recaptchaToken'] = recaptchaToken;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeatSpaceWrapperApi - functional programming interface
 * @export
 */
export const MeatSpaceWrapperApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} meatSpacePath 
         * @param {number} [cache] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meatSpaceWrapperGet(meatSpacePath: string, cache?: number, lang?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = MeatSpaceWrapperApiFetchParamCreator(configuration).meatSpaceWrapperGet(meatSpacePath, cache, lang, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} meatSpacePath 
         * @param {string} data 
         * @param {string} [lang] 
         * @param {string} [recaptchaToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meatSpaceWrapperPost(meatSpacePath: string, data: string, lang?: string, recaptchaToken?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = MeatSpaceWrapperApiFetchParamCreator(configuration).meatSpaceWrapperPost(meatSpacePath, data, lang, recaptchaToken, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MeatSpaceWrapperApi - factory interface
 * @export
 */
export const MeatSpaceWrapperApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} meatSpacePath 
         * @param {number} [cache] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meatSpaceWrapperGet(meatSpacePath: string, cache?: number, lang?: string, options?: any) {
            return MeatSpaceWrapperApiFp(configuration).meatSpaceWrapperGet(meatSpacePath, cache, lang, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} meatSpacePath 
         * @param {string} data 
         * @param {string} [lang] 
         * @param {string} [recaptchaToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meatSpaceWrapperPost(meatSpacePath: string, data: string, lang?: string, recaptchaToken?: string, options?: any) {
            return MeatSpaceWrapperApiFp(configuration).meatSpaceWrapperPost(meatSpacePath, data, lang, recaptchaToken, options)(fetch, basePath);
        },
    };
};

/**
 * MeatSpaceWrapperApi - object-oriented interface
 * @export
 * @class MeatSpaceWrapperApi
 * @extends {BaseAPI}
 */
export class MeatSpaceWrapperApi extends BaseAPI {
    /**
     * 
     * @param {string} meatSpacePath 
     * @param {number} [cache] 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeatSpaceWrapperApi
     */
    public meatSpaceWrapperGet(meatSpacePath: string, cache?: number, lang?: string, options?: any) {
        return MeatSpaceWrapperApiFp(this.configuration).meatSpaceWrapperGet(meatSpacePath, cache, lang, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} meatSpacePath 
     * @param {string} data 
     * @param {string} [lang] 
     * @param {string} [recaptchaToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeatSpaceWrapperApi
     */
    public meatSpaceWrapperPost(meatSpacePath: string, data: string, lang?: string, recaptchaToken?: string, options?: any) {
        return MeatSpaceWrapperApiFp(this.configuration).meatSpaceWrapperPost(meatSpacePath, data, lang, recaptchaToken, options)(this.fetch, this.basePath);
    }

}

/**
 * NavigationsApi - fetch parameter creator
 * @export
 */
export const NavigationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNavigation(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'nodeAliasPath' is not null or undefined
            if (nodeAliasPath === null || nodeAliasPath === undefined) {
                throw new RequiredError('nodeAliasPath','Required parameter nodeAliasPath was null or undefined when calling navigationsGetNavigation.');
            }
            const localVarPath = `/czt/api/navigation/{nodeAliasPath}`
                .replace(`{${"nodeAliasPath"}}`, encodeURIComponent(String(nodeAliasPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNavigations(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'nodeAliasPath' is not null or undefined
            if (nodeAliasPath === null || nodeAliasPath === undefined) {
                throw new RequiredError('nodeAliasPath','Required parameter nodeAliasPath was null or undefined when calling navigationsGetNavigations.');
            }
            const localVarPath = `/czt/api/navigations/{nodeAliasPath}`
                .replace(`{${"nodeAliasPath"}}`, encodeURIComponent(String(nodeAliasPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NavigationsApi - functional programming interface
 * @export
 */
export const NavigationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNavigation(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NavigationFolder> {
            const localVarFetchArgs = NavigationsApiFetchParamCreator(configuration).navigationsGetNavigation(nodeAliasPath, cultureCode, isPreview, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNavigations(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NavigationFolder>> {
            const localVarFetchArgs = NavigationsApiFetchParamCreator(configuration).navigationsGetNavigations(nodeAliasPath, cultureCode, isPreview, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NavigationsApi - factory interface
 * @export
 */
export const NavigationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNavigation(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options?: any) {
            return NavigationsApiFp(configuration).navigationsGetNavigation(nodeAliasPath, cultureCode, isPreview, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navigationsGetNavigations(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options?: any) {
            return NavigationsApiFp(configuration).navigationsGetNavigations(nodeAliasPath, cultureCode, isPreview, options)(fetch, basePath);
        },
    };
};

/**
 * NavigationsApi - object-oriented interface
 * @export
 * @class NavigationsApi
 * @extends {BaseAPI}
 */
export class NavigationsApi extends BaseAPI {
    /**
     * 
     * @param {string} nodeAliasPath 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationsApi
     */
    public navigationsGetNavigation(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options?: any) {
        return NavigationsApiFp(this.configuration).navigationsGetNavigation(nodeAliasPath, cultureCode, isPreview, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} nodeAliasPath 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavigationsApi
     */
    public navigationsGetNavigations(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options?: any) {
        return NavigationsApiFp(this.configuration).navigationsGetNavigations(nodeAliasPath, cultureCode, isPreview, options)(this.fetch, this.basePath);
    }

}

/**
 * NewsletterApi - fetch parameter creator
 * @export
 */
export const NewsletterApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} newsletterName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterGetNewsletter(newsletterName: string, options: any = {}): FetchArgs {
            // verify required parameter 'newsletterName' is not null or undefined
            if (newsletterName === null || newsletterName === undefined) {
                throw new RequiredError('newsletterName','Required parameter newsletterName was null or undefined when calling newsletterGetNewsletter.');
            }
            const localVarPath = `/czt/api/newsletter`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (newsletterName !== undefined) {
                localVarQueryParameter['newsletterName'] = newsletterName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterGetNewsletters(options: any = {}): FetchArgs {
            const localVarPath = `/czt/api/newsletters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewsletterContact} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterSubscribe(contact: NewsletterContact, options: any = {}): FetchArgs {
            // verify required parameter 'contact' is not null or undefined
            if (contact === null || contact === undefined) {
                throw new RequiredError('contact','Required parameter contact was null or undefined when calling newsletterSubscribe.');
            }
            const localVarPath = `/czt/api/newsletter/subscribe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NewsletterContact" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(contact || {}) : (contact || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsletterApi - functional programming interface
 * @export
 */
export const NewsletterApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} newsletterName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterGetNewsletter(newsletterName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NewsletterInfo> {
            const localVarFetchArgs = NewsletterApiFetchParamCreator(configuration).newsletterGetNewsletter(newsletterName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterGetNewsletters(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NewsletterInfo>> {
            const localVarFetchArgs = NewsletterApiFetchParamCreator(configuration).newsletterGetNewsletters(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {NewsletterContact} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterSubscribe(contact: NewsletterContact, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = NewsletterApiFetchParamCreator(configuration).newsletterSubscribe(contact, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NewsletterApi - factory interface
 * @export
 */
export const NewsletterApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} newsletterName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterGetNewsletter(newsletterName: string, options?: any) {
            return NewsletterApiFp(configuration).newsletterGetNewsletter(newsletterName, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterGetNewsletters(options?: any) {
            return NewsletterApiFp(configuration).newsletterGetNewsletters(options)(fetch, basePath);
        },
        /**
         * 
         * @param {NewsletterContact} contact 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsletterSubscribe(contact: NewsletterContact, options?: any) {
            return NewsletterApiFp(configuration).newsletterSubscribe(contact, options)(fetch, basePath);
        },
    };
};

/**
 * NewsletterApi - object-oriented interface
 * @export
 * @class NewsletterApi
 * @extends {BaseAPI}
 */
export class NewsletterApi extends BaseAPI {
    /**
     * 
     * @param {string} newsletterName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApi
     */
    public newsletterGetNewsletter(newsletterName: string, options?: any) {
        return NewsletterApiFp(this.configuration).newsletterGetNewsletter(newsletterName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApi
     */
    public newsletterGetNewsletters(options?: any) {
        return NewsletterApiFp(this.configuration).newsletterGetNewsletters(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {NewsletterContact} contact 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApi
     */
    public newsletterSubscribe(contact: NewsletterContact, options?: any) {
        return NewsletterApiFp(this.configuration).newsletterSubscribe(contact, options)(this.fetch, this.basePath);
    }

}

/**
 * PagesApi - fetch parameter creator
 * @export
 */
export const PagesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPage(nodeAliasPath: string, cultureCode?: string, className?: string, isPreview?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'nodeAliasPath' is not null or undefined
            if (nodeAliasPath === null || nodeAliasPath === undefined) {
                throw new RequiredError('nodeAliasPath','Required parameter nodeAliasPath was null or undefined when calling pagesGetPage.');
            }
            const localVarPath = `/czt/api/page/{nodeAliasPath}`
                .replace(`{${"nodeAliasPath"}}`, encodeURIComponent(String(nodeAliasPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (className !== undefined) {
                localVarQueryParameter['className'] = className;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPageByGuid(nodeGuid: string, cultureCode?: string, className?: string, isPreview?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'nodeGuid' is not null or undefined
            if (nodeGuid === null || nodeGuid === undefined) {
                throw new RequiredError('nodeGuid','Required parameter nodeGuid was null or undefined when calling pagesGetPageByGuid.');
            }
            const localVarPath = `/czt/api/page/{nodeGuid}/`
                .replace(`{${"nodeGuid"}}`, encodeURIComponent(String(nodeGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (className !== undefined) {
                localVarQueryParameter['className'] = className;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPages(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'nodeAliasPath' is not null or undefined
            if (nodeAliasPath === null || nodeAliasPath === undefined) {
                throw new RequiredError('nodeAliasPath','Required parameter nodeAliasPath was null or undefined when calling pagesGetPages.');
            }
            const localVarPath = `/czt/api/pages/{nodeAliasPath}`
                .replace(`{${"nodeAliasPath"}}`, encodeURIComponent(String(nodeAliasPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPagesByGuid(nodeGuid: string, cultureCode?: string, isPreview?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'nodeGuid' is not null or undefined
            if (nodeGuid === null || nodeGuid === undefined) {
                throw new RequiredError('nodeGuid','Required parameter nodeGuid was null or undefined when calling pagesGetPagesByGuid.');
            }
            const localVarPath = `/czt/api/pages/{nodeGuid}/`
                .replace(`{${"nodeGuid"}}`, encodeURIComponent(String(nodeGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PagesApi - functional programming interface
 * @export
 */
export const PagesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPage(nodeAliasPath: string, cultureCode?: string, className?: string, isPreview?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BasePage> {
            const localVarFetchArgs = PagesApiFetchParamCreator(configuration).pagesGetPage(nodeAliasPath, cultureCode, className, isPreview, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPageByGuid(nodeGuid: string, cultureCode?: string, className?: string, isPreview?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BasePage> {
            const localVarFetchArgs = PagesApiFetchParamCreator(configuration).pagesGetPageByGuid(nodeGuid, cultureCode, className, isPreview, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPages(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<BasePage>> {
            const localVarFetchArgs = PagesApiFetchParamCreator(configuration).pagesGetPages(nodeAliasPath, cultureCode, isPreview, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPagesByGuid(nodeGuid: string, cultureCode?: string, isPreview?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<BasePage>> {
            const localVarFetchArgs = PagesApiFetchParamCreator(configuration).pagesGetPagesByGuid(nodeGuid, cultureCode, isPreview, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PagesApi - factory interface
 * @export
 */
export const PagesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPage(nodeAliasPath: string, cultureCode?: string, className?: string, isPreview?: boolean, options?: any) {
            return PagesApiFp(configuration).pagesGetPage(nodeAliasPath, cultureCode, className, isPreview, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPageByGuid(nodeGuid: string, cultureCode?: string, className?: string, isPreview?: boolean, options?: any) {
            return PagesApiFp(configuration).pagesGetPageByGuid(nodeGuid, cultureCode, className, isPreview, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPages(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options?: any) {
            return PagesApiFp(configuration).pagesGetPages(nodeAliasPath, cultureCode, isPreview, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pagesGetPagesByGuid(nodeGuid: string, cultureCode?: string, isPreview?: boolean, options?: any) {
            return PagesApiFp(configuration).pagesGetPagesByGuid(nodeGuid, cultureCode, isPreview, options)(fetch, basePath);
        },
    };
};

/**
 * PagesApi - object-oriented interface
 * @export
 * @class PagesApi
 * @extends {BaseAPI}
 */
export class PagesApi extends BaseAPI {
    /**
     * 
     * @param {string} nodeAliasPath 
     * @param {string} [cultureCode] 
     * @param {string} [className] 
     * @param {boolean} [isPreview] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public pagesGetPage(nodeAliasPath: string, cultureCode?: string, className?: string, isPreview?: boolean, options?: any) {
        return PagesApiFp(this.configuration).pagesGetPage(nodeAliasPath, cultureCode, className, isPreview, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} nodeGuid 
     * @param {string} [cultureCode] 
     * @param {string} [className] 
     * @param {boolean} [isPreview] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public pagesGetPageByGuid(nodeGuid: string, cultureCode?: string, className?: string, isPreview?: boolean, options?: any) {
        return PagesApiFp(this.configuration).pagesGetPageByGuid(nodeGuid, cultureCode, className, isPreview, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} nodeAliasPath 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public pagesGetPages(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options?: any) {
        return PagesApiFp(this.configuration).pagesGetPages(nodeAliasPath, cultureCode, isPreview, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} nodeGuid 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagesApi
     */
    public pagesGetPagesByGuid(nodeGuid: string, cultureCode?: string, isPreview?: boolean, options?: any) {
        return PagesApiFp(this.configuration).pagesGetPagesByGuid(nodeGuid, cultureCode, isPreview, options)(this.fetch, this.basePath);
    }

}

/**
 * RegionsApi - fetch parameter creator
 * @export
 */
export const RegionsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} regionGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsGetRegion(regionGuid: string, cultureCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'regionGuid' is not null or undefined
            if (regionGuid === null || regionGuid === undefined) {
                throw new RequiredError('regionGuid','Required parameter regionGuid was null or undefined when calling regionsGetRegion.');
            }
            const localVarPath = `/czt/api/region/{regionGuid}`
                .replace(`{${"regionGuid"}}`, encodeURIComponent(String(regionGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsGetRegions(cultureCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/czt/api/regions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionsApi - functional programming interface
 * @export
 */
export const RegionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} regionGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsGetRegion(regionGuid: string, cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Region> {
            const localVarFetchArgs = RegionsApiFetchParamCreator(configuration).regionsGetRegion(regionGuid, cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsGetRegions(cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Region>> {
            const localVarFetchArgs = RegionsApiFetchParamCreator(configuration).regionsGetRegions(cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RegionsApi - factory interface
 * @export
 */
export const RegionsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} regionGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsGetRegion(regionGuid: string, cultureCode?: string, options?: any) {
            return RegionsApiFp(configuration).regionsGetRegion(regionGuid, cultureCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsGetRegions(cultureCode?: string, options?: any) {
            return RegionsApiFp(configuration).regionsGetRegions(cultureCode, options)(fetch, basePath);
        },
    };
};

/**
 * RegionsApi - object-oriented interface
 * @export
 * @class RegionsApi
 * @extends {BaseAPI}
 */
export class RegionsApi extends BaseAPI {
    /**
     * 
     * @param {string} regionGuid 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public regionsGetRegion(regionGuid: string, cultureCode?: string, options?: any) {
        return RegionsApiFp(this.configuration).regionsGetRegion(regionGuid, cultureCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public regionsGetRegions(cultureCode?: string, options?: any) {
        return RegionsApiFp(this.configuration).regionsGetRegions(cultureCode, options)(this.fetch, this.basePath);
    }

}

/**
 * ResourcesApi - fetch parameter creator
 * @export
 */
export const ResourcesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} resourceKey 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesGetResource(resourceKey: string, cultureCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'resourceKey' is not null or undefined
            if (resourceKey === null || resourceKey === undefined) {
                throw new RequiredError('resourceKey','Required parameter resourceKey was null or undefined when calling resourcesGetResource.');
            }
            const localVarPath = `/czt/api/resource`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (resourceKey !== undefined) {
                localVarQueryParameter['resourceKey'] = resourceKey;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesGetResources(cultureCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/czt/api/resources`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourcesApi - functional programming interface
 * @export
 */
export const ResourcesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} resourceKey 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesGetResource(resourceKey: string, cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResourceString> {
            const localVarFetchArgs = ResourcesApiFetchParamCreator(configuration).resourcesGetResource(resourceKey, cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesGetResources(cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ResourceString>> {
            const localVarFetchArgs = ResourcesApiFetchParamCreator(configuration).resourcesGetResources(cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ResourcesApi - factory interface
 * @export
 */
export const ResourcesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} resourceKey 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesGetResource(resourceKey: string, cultureCode?: string, options?: any) {
            return ResourcesApiFp(configuration).resourcesGetResource(resourceKey, cultureCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resourcesGetResources(cultureCode?: string, options?: any) {
            return ResourcesApiFp(configuration).resourcesGetResources(cultureCode, options)(fetch, basePath);
        },
    };
};

/**
 * ResourcesApi - object-oriented interface
 * @export
 * @class ResourcesApi
 * @extends {BaseAPI}
 */
export class ResourcesApi extends BaseAPI {
    /**
     * 
     * @param {string} resourceKey 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesApi
     */
    public resourcesGetResource(resourceKey: string, cultureCode?: string, options?: any) {
        return ResourcesApiFp(this.configuration).resourcesGetResource(resourceKey, cultureCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesApi
     */
    public resourcesGetResources(cultureCode?: string, options?: any) {
        return ResourcesApiFp(this.configuration).resourcesGetResources(cultureCode, options)(this.fetch, this.basePath);
    }

}

/**
 * SafeTravelStampsApi - fetch parameter creator
 * @export
 */
export const SafeTravelStampsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} formId 
         * @param {string} id 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        safeTravelStampsGetItem(formId: number, id: string, cultureCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'formId' is not null or undefined
            if (formId === null || formId === undefined) {
                throw new RequiredError('formId','Required parameter formId was null or undefined when calling safeTravelStampsGetItem.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling safeTravelStampsGetItem.');
            }
            const localVarPath = `/czt/api/safetravelstamp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (formId !== undefined) {
                localVarQueryParameter['formId'] = formId;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [approvedOnly] 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        safeTravelStampsGetItems(approvedOnly?: boolean, cultureCode?: string, options: any = {}): FetchArgs {
            const localVarPath = `/czt/api/safetravelstamps`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (approvedOnly !== undefined) {
                localVarQueryParameter['approvedOnly'] = approvedOnly;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SafeTravelStampsApi - functional programming interface
 * @export
 */
export const SafeTravelStampsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} formId 
         * @param {string} id 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        safeTravelStampsGetItem(formId: number, id: string, cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SafeTravelsStampItem> {
            const localVarFetchArgs = SafeTravelStampsApiFetchParamCreator(configuration).safeTravelStampsGetItem(formId, id, cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {boolean} [approvedOnly] 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        safeTravelStampsGetItems(approvedOnly?: boolean, cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SafeTravelsStampItem>> {
            const localVarFetchArgs = SafeTravelStampsApiFetchParamCreator(configuration).safeTravelStampsGetItems(approvedOnly, cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SafeTravelStampsApi - factory interface
 * @export
 */
export const SafeTravelStampsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} formId 
         * @param {string} id 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        safeTravelStampsGetItem(formId: number, id: string, cultureCode?: string, options?: any) {
            return SafeTravelStampsApiFp(configuration).safeTravelStampsGetItem(formId, id, cultureCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {boolean} [approvedOnly] 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        safeTravelStampsGetItems(approvedOnly?: boolean, cultureCode?: string, options?: any) {
            return SafeTravelStampsApiFp(configuration).safeTravelStampsGetItems(approvedOnly, cultureCode, options)(fetch, basePath);
        },
    };
};

/**
 * SafeTravelStampsApi - object-oriented interface
 * @export
 * @class SafeTravelStampsApi
 * @extends {BaseAPI}
 */
export class SafeTravelStampsApi extends BaseAPI {
    /**
     * 
     * @param {number} formId 
     * @param {string} id 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafeTravelStampsApi
     */
    public safeTravelStampsGetItem(formId: number, id: string, cultureCode?: string, options?: any) {
        return SafeTravelStampsApiFp(this.configuration).safeTravelStampsGetItem(formId, id, cultureCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {boolean} [approvedOnly] 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SafeTravelStampsApi
     */
    public safeTravelStampsGetItems(approvedOnly?: boolean, cultureCode?: string, options?: any) {
        return SafeTravelStampsApiFp(this.configuration).safeTravelStampsGetItems(approvedOnly, cultureCode, options)(this.fetch, this.basePath);
    }

}

/**
 * SearchApi - fetch parameter creator
 * @export
 */
export const SearchApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} searchTerm 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGetSearchResult(searchTerm: string, pageNum?: number, pageSize?: number, cultureCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'searchTerm' is not null or undefined
            if (searchTerm === null || searchTerm === undefined) {
                throw new RequiredError('searchTerm','Required parameter searchTerm was null or undefined when calling searchGetSearchResult.');
            }
            const localVarPath = `/czt/api/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} searchTerm 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGetSearchResult(searchTerm: string, pageNum?: number, pageSize?: number, cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SearchResults> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchGetSearchResult(searchTerm, pageNum, pageSize, cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} searchTerm 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGetSearchResult(searchTerm: string, pageNum?: number, pageSize?: number, cultureCode?: string, options?: any) {
            return SearchApiFp(configuration).searchGetSearchResult(searchTerm, pageNum, pageSize, cultureCode, options)(fetch, basePath);
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @param {string} searchTerm 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchGetSearchResult(searchTerm: string, pageNum?: number, pageSize?: number, cultureCode?: string, options?: any) {
        return SearchApiFp(this.configuration).searchGetSearchResult(searchTerm, pageNum, pageSize, cultureCode, options)(this.fetch, this.basePath);
    }

}

/**
 * SiteCulturesApi - fetch parameter creator
 * @export
 */
export const SiteCulturesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} cultureCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCulturesGetSiteCulture(cultureCode: string, options: any = {}): FetchArgs {
            // verify required parameter 'cultureCode' is not null or undefined
            if (cultureCode === null || cultureCode === undefined) {
                throw new RequiredError('cultureCode','Required parameter cultureCode was null or undefined when calling siteCulturesGetSiteCulture.');
            }
            const localVarPath = `/czt/api/siteculture`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCulturesGetSiteCultures(options: any = {}): FetchArgs {
            const localVarPath = `/czt/api/sitecultures`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteCulturesApi - functional programming interface
 * @export
 */
export const SiteCulturesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} cultureCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCulturesGetSiteCulture(cultureCode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SiteCulture> {
            const localVarFetchArgs = SiteCulturesApiFetchParamCreator(configuration).siteCulturesGetSiteCulture(cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCulturesGetSiteCultures(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SiteCulture>> {
            const localVarFetchArgs = SiteCulturesApiFetchParamCreator(configuration).siteCulturesGetSiteCultures(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SiteCulturesApi - factory interface
 * @export
 */
export const SiteCulturesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} cultureCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCulturesGetSiteCulture(cultureCode: string, options?: any) {
            return SiteCulturesApiFp(configuration).siteCulturesGetSiteCulture(cultureCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteCulturesGetSiteCultures(options?: any) {
            return SiteCulturesApiFp(configuration).siteCulturesGetSiteCultures(options)(fetch, basePath);
        },
    };
};

/**
 * SiteCulturesApi - object-oriented interface
 * @export
 * @class SiteCulturesApi
 * @extends {BaseAPI}
 */
export class SiteCulturesApi extends BaseAPI {
    /**
     * 
     * @param {string} cultureCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteCulturesApi
     */
    public siteCulturesGetSiteCulture(cultureCode: string, options?: any) {
        return SiteCulturesApiFp(this.configuration).siteCulturesGetSiteCulture(cultureCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteCulturesApi
     */
    public siteCulturesGetSiteCultures(options?: any) {
        return SiteCulturesApiFp(this.configuration).siteCulturesGetSiteCultures(options)(this.fetch, this.basePath);
    }

}

/**
 * SubCategoriesApi - fetch parameter creator
 * @export
 */
export const SubCategoriesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} categoryGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subCategoriesGetSubCategories(categoryGuid: string, cultureCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'categoryGuid' is not null or undefined
            if (categoryGuid === null || categoryGuid === undefined) {
                throw new RequiredError('categoryGuid','Required parameter categoryGuid was null or undefined when calling subCategoriesGetSubCategories.');
            }
            const localVarPath = `/czt/api/subcategories/{categoryGuid}`
                .replace(`{${"categoryGuid"}}`, encodeURIComponent(String(categoryGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subCategoryGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subCategoriesGetSubCategory(subCategoryGuid: string, cultureCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'subCategoryGuid' is not null or undefined
            if (subCategoryGuid === null || subCategoryGuid === undefined) {
                throw new RequiredError('subCategoryGuid','Required parameter subCategoryGuid was null or undefined when calling subCategoriesGetSubCategory.');
            }
            const localVarPath = `/czt/api/subcategory/{subCategoryGuid}`
                .replace(`{${"subCategoryGuid"}}`, encodeURIComponent(String(subCategoryGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubCategoriesApi - functional programming interface
 * @export
 */
export const SubCategoriesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} categoryGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subCategoriesGetSubCategories(categoryGuid: string, cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubCategory>> {
            const localVarFetchArgs = SubCategoriesApiFetchParamCreator(configuration).subCategoriesGetSubCategories(categoryGuid, cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} subCategoryGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subCategoriesGetSubCategory(subCategoryGuid: string, cultureCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubCategory> {
            const localVarFetchArgs = SubCategoriesApiFetchParamCreator(configuration).subCategoriesGetSubCategory(subCategoryGuid, cultureCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SubCategoriesApi - factory interface
 * @export
 */
export const SubCategoriesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} categoryGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subCategoriesGetSubCategories(categoryGuid: string, cultureCode?: string, options?: any) {
            return SubCategoriesApiFp(configuration).subCategoriesGetSubCategories(categoryGuid, cultureCode, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} subCategoryGuid 
         * @param {string} [cultureCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subCategoriesGetSubCategory(subCategoryGuid: string, cultureCode?: string, options?: any) {
            return SubCategoriesApiFp(configuration).subCategoriesGetSubCategory(subCategoryGuid, cultureCode, options)(fetch, basePath);
        },
    };
};

/**
 * SubCategoriesApi - object-oriented interface
 * @export
 * @class SubCategoriesApi
 * @extends {BaseAPI}
 */
export class SubCategoriesApi extends BaseAPI {
    /**
     * 
     * @param {string} categoryGuid 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubCategoriesApi
     */
    public subCategoriesGetSubCategories(categoryGuid: string, cultureCode?: string, options?: any) {
        return SubCategoriesApiFp(this.configuration).subCategoriesGetSubCategories(categoryGuid, cultureCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} subCategoryGuid 
     * @param {string} [cultureCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubCategoriesApi
     */
    public subCategoriesGetSubCategory(subCategoryGuid: string, cultureCode?: string, options?: any) {
        return SubCategoriesApiFp(this.configuration).subCategoriesGetSubCategory(subCategoryGuid, cultureCode, options)(this.fetch, this.basePath);
    }

}

/**
 * TranslationsApi - fetch parameter creator
 * @export
 */
export const TranslationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AutomatedTranslationVoting} voting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsAutomatedTranslationVoting(voting: AutomatedTranslationVoting, options: any = {}): FetchArgs {
            // verify required parameter 'voting' is not null or undefined
            if (voting === null || voting === undefined) {
                throw new RequiredError('voting','Required parameter voting was null or undefined when calling translationsAutomatedTranslationVoting.');
            }
            const localVarPath = `/czt/api/translations/voting`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AutomatedTranslationVoting" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(voting || {}) : (voting || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationsApi - functional programming interface
 * @export
 */
export const TranslationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AutomatedTranslationVoting} voting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsAutomatedTranslationVoting(voting: AutomatedTranslationVoting, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VotingResponse> {
            const localVarFetchArgs = TranslationsApiFetchParamCreator(configuration).translationsAutomatedTranslationVoting(voting, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TranslationsApi - factory interface
 * @export
 */
export const TranslationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AutomatedTranslationVoting} voting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsAutomatedTranslationVoting(voting: AutomatedTranslationVoting, options?: any) {
            return TranslationsApiFp(configuration).translationsAutomatedTranslationVoting(voting, options)(fetch, basePath);
        },
    };
};

/**
 * TranslationsApi - object-oriented interface
 * @export
 * @class TranslationsApi
 * @extends {BaseAPI}
 */
export class TranslationsApi extends BaseAPI {
    /**
     * 
     * @param {AutomatedTranslationVoting} voting 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public translationsAutomatedTranslationVoting(voting: AutomatedTranslationVoting, options?: any) {
        return TranslationsApiFp(this.configuration).translationsAutomatedTranslationVoting(voting, options)(this.fetch, this.basePath);
    }

}

/**
 * WidgetsApi - fetch parameter creator
 * @export
 */
export const WidgetsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {number} [pageNum] 
         * @param {string} [parentPageGuid] 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidget(nodeAliasPath: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, isPreview?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'nodeAliasPath' is not null or undefined
            if (nodeAliasPath === null || nodeAliasPath === undefined) {
                throw new RequiredError('nodeAliasPath','Required parameter nodeAliasPath was null or undefined when calling widgetsGetWidget.');
            }
            const localVarPath = `/czt/api/widget/{nodeAliasPath}`
                .replace(`{${"nodeAliasPath"}}`, encodeURIComponent(String(nodeAliasPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (parentPageGuid !== undefined) {
                localVarQueryParameter['parentPageGuid'] = parentPageGuid;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (className !== undefined) {
                localVarQueryParameter['className'] = className;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {number} [pageNum] 
         * @param {string} [parentPageGuid] 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgetByGuid(nodeGuid: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, isPreview?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'nodeGuid' is not null or undefined
            if (nodeGuid === null || nodeGuid === undefined) {
                throw new RequiredError('nodeGuid','Required parameter nodeGuid was null or undefined when calling widgetsGetWidgetByGuid.');
            }
            const localVarPath = `/czt/api/widget/{nodeGuid}/`
                .replace(`{${"nodeGuid"}}`, encodeURIComponent(String(nodeGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (parentPageGuid !== undefined) {
                localVarQueryParameter['parentPageGuid'] = parentPageGuid;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (className !== undefined) {
                localVarQueryParameter['className'] = className;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgets(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'nodeAliasPath' is not null or undefined
            if (nodeAliasPath === null || nodeAliasPath === undefined) {
                throw new RequiredError('nodeAliasPath','Required parameter nodeAliasPath was null or undefined when calling widgetsGetWidgets.');
            }
            const localVarPath = `/czt/api/widgets/{nodeAliasPath}`
                .replace(`{${"nodeAliasPath"}}`, encodeURIComponent(String(nodeAliasPath)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parentNodeGuid 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgetsByGuid(parentNodeGuid: string, nodeGuid: string, cultureCode?: string, isPreview?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'parentNodeGuid' is not null or undefined
            if (parentNodeGuid === null || parentNodeGuid === undefined) {
                throw new RequiredError('parentNodeGuid','Required parameter parentNodeGuid was null or undefined when calling widgetsGetWidgetsByGuid.');
            }
            // verify required parameter 'nodeGuid' is not null or undefined
            if (nodeGuid === null || nodeGuid === undefined) {
                throw new RequiredError('nodeGuid','Required parameter nodeGuid was null or undefined when calling widgetsGetWidgetsByGuid.');
            }
            const localVarPath = `/czt/api/widgets/{nodeGuid}/`
                .replace(`{${"nodeGuid"}}`, encodeURIComponent(String(nodeGuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (parentNodeGuid !== undefined) {
                localVarQueryParameter['parentNodeGuid'] = parentNodeGuid;
            }

            if (cultureCode !== undefined) {
                localVarQueryParameter['cultureCode'] = cultureCode;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WidgetsApi - functional programming interface
 * @export
 */
export const WidgetsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {number} [pageNum] 
         * @param {string} [parentPageGuid] 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidget(nodeAliasPath: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, isPreview?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BaseWidget> {
            const localVarFetchArgs = WidgetsApiFetchParamCreator(configuration).widgetsGetWidget(nodeAliasPath, pageNum, parentPageGuid, cultureCode, className, isPreview, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {number} [pageNum] 
         * @param {string} [parentPageGuid] 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgetByGuid(nodeGuid: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, isPreview?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BaseWidget> {
            const localVarFetchArgs = WidgetsApiFetchParamCreator(configuration).widgetsGetWidgetByGuid(nodeGuid, pageNum, parentPageGuid, cultureCode, className, isPreview, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgets(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<BaseWidget>> {
            const localVarFetchArgs = WidgetsApiFetchParamCreator(configuration).widgetsGetWidgets(nodeAliasPath, cultureCode, isPreview, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} parentNodeGuid 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgetsByGuid(parentNodeGuid: string, nodeGuid: string, cultureCode?: string, isPreview?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<BaseWidget>> {
            const localVarFetchArgs = WidgetsApiFetchParamCreator(configuration).widgetsGetWidgetsByGuid(parentNodeGuid, nodeGuid, cultureCode, isPreview, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * WidgetsApi - factory interface
 * @export
 */
export const WidgetsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {number} [pageNum] 
         * @param {string} [parentPageGuid] 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidget(nodeAliasPath: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, isPreview?: boolean, options?: any) {
            return WidgetsApiFp(configuration).widgetsGetWidget(nodeAliasPath, pageNum, parentPageGuid, cultureCode, className, isPreview, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} nodeGuid 
         * @param {number} [pageNum] 
         * @param {string} [parentPageGuid] 
         * @param {string} [cultureCode] 
         * @param {string} [className] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgetByGuid(nodeGuid: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, isPreview?: boolean, options?: any) {
            return WidgetsApiFp(configuration).widgetsGetWidgetByGuid(nodeGuid, pageNum, parentPageGuid, cultureCode, className, isPreview, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} nodeAliasPath 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgets(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options?: any) {
            return WidgetsApiFp(configuration).widgetsGetWidgets(nodeAliasPath, cultureCode, isPreview, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} parentNodeGuid 
         * @param {string} nodeGuid 
         * @param {string} [cultureCode] 
         * @param {boolean} [isPreview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetsGetWidgetsByGuid(parentNodeGuid: string, nodeGuid: string, cultureCode?: string, isPreview?: boolean, options?: any) {
            return WidgetsApiFp(configuration).widgetsGetWidgetsByGuid(parentNodeGuid, nodeGuid, cultureCode, isPreview, options)(fetch, basePath);
        },
    };
};

/**
 * WidgetsApi - object-oriented interface
 * @export
 * @class WidgetsApi
 * @extends {BaseAPI}
 */
export class WidgetsApi extends BaseAPI {
    /**
     * 
     * @param {string} nodeAliasPath 
     * @param {number} [pageNum] 
     * @param {string} [parentPageGuid] 
     * @param {string} [cultureCode] 
     * @param {string} [className] 
     * @param {boolean} [isPreview] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetsGetWidget(nodeAliasPath: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, isPreview?: boolean, options?: any) {
        return WidgetsApiFp(this.configuration).widgetsGetWidget(nodeAliasPath, pageNum, parentPageGuid, cultureCode, className, isPreview, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} nodeGuid 
     * @param {number} [pageNum] 
     * @param {string} [parentPageGuid] 
     * @param {string} [cultureCode] 
     * @param {string} [className] 
     * @param {boolean} [isPreview] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetsGetWidgetByGuid(nodeGuid: string, pageNum?: number, parentPageGuid?: string, cultureCode?: string, className?: string, isPreview?: boolean, options?: any) {
        return WidgetsApiFp(this.configuration).widgetsGetWidgetByGuid(nodeGuid, pageNum, parentPageGuid, cultureCode, className, isPreview, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} nodeAliasPath 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetsGetWidgets(nodeAliasPath: string, cultureCode?: string, isPreview?: boolean, options?: any) {
        return WidgetsApiFp(this.configuration).widgetsGetWidgets(nodeAliasPath, cultureCode, isPreview, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} parentNodeGuid 
     * @param {string} nodeGuid 
     * @param {string} [cultureCode] 
     * @param {boolean} [isPreview] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetsGetWidgetsByGuid(parentNodeGuid: string, nodeGuid: string, cultureCode?: string, isPreview?: boolean, options?: any) {
        return WidgetsApiFp(this.configuration).widgetsGetWidgetsByGuid(parentNodeGuid, nodeGuid, cultureCode, isPreview, options)(this.fetch, this.basePath);
    }

}

